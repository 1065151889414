<template>
    <v-card style="min-width:300px;width:300px;margin-top: 0px !important" class="navigation">
        <v-list-item @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'calendar')" prepend-icon="mdi-calendar" link title="Kalender" class="navigation-item"></v-list-item>
        <v-list-item @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'ministrations')" prepend-icon="mdi-book" link title="Dienstleistungen" class="navigation-item"></v-list-item>
        <v-list-item @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'customers')" prepend-icon="mdi-account-group" link title="Kundenverwaltung" class="navigation-item"></v-list-item>
        <v-list-item @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'employees')" :class="{'EliasScharf_Hide': this.$store.state.Config.Authentication.Data.Admin == false}" prepend-icon="mdi-account-group" link title="Mitarbeiterverwaltung" class="navigation-item"></v-list-item>
        <!--<v-list-item prepend-icon="mdi-server" link title="Lagerverwaltung" class="navigation-item"></v-list-item>-->
        <!--<v-list-item prepend-icon="mdi-poll" link title="Analyse" class="navigation-item"></v-list-item>-->
        <v-list-item disabled link title="Unternehmen" class="navigation-item" style="background:transparent !important;"></v-list-item>
        <v-list-item disabled @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'opentimes')" prepend-icon="mdi-clock-outline" link title="Öffnungszeiten" class="navigation-item"></v-list-item>
        <v-list-item disabled @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'holidays')" prepend-icon="mdi-caravan" link title="Feiertage" class="navigation-item"></v-list-item>
        <v-list-item disabled @click="this.$router.push(this.$store.state.Config.Application.Salonmanager.PostURL+'license')" prepend-icon="mdi-home-city" link title="Lizenz" class="navigation-item"></v-list-item>
    </v-card>
</template>

<script>
export default {
    name: "Header-Component",
}
</script>

<style scoped>
.navigation-item {
    min-height:45px !important;
}
@media(max-width: 1280px) {
    .navigation {
        transform: translateX(0%) !important;
        box-shadow: none;
    }
}
@media(max-width:1100px) {
    .navigation {
        width: 0px !important;
        overflow: hidden !important;
        height:100vh;
        background:white !important;
        z-index: 999;
    }
}
</style>