<template>
  <div class="authentication-container">
    <Sidebar />

    <div class="authentication-form">
      <h5 class="text-h5 typeface-figtree">Kundenlogin</h5>
      <p class="text-body-1">Mit Elias-Scharf-Konto anmelden.</p>
      
      <v-text-field type="email" v-model="email" clearable label="E-Mail eingeben..." variant="outlined" class="authentication-textbox"></v-text-field>
      <v-text-field type="password" v-model="password" clearable label="Kennwort eingeben..." variant="outlined" class="authentication-textbox"></v-text-field>
      <v-checkbox label="Gerät merken" v-model="remember_device" class="authentication-checkbox" color="primary"></v-checkbox>

      <div class="authentication-actions">
        <div class="width-100"></div>
        <v-btn color="primary" rounded class="authentication-button" @click="this.SignIn('/authentication/signin')">&nbsp;Anmelden&nbsp;</v-btn>
      </div>

      <p class="text-body-1">Sollten Sie bei der Anmeldung Hilfe benötigen, finden Sie diese auf unserer Elias Scharf <a :href="this.$store.state.Config.Url.Help" target="_blank" class="authentication-link">Hilfe-Seite</a>.</p>
    </div>
  </div>
  <v-alert color="red" :class="{'EliasScharf_Hide': !alert.show}" icon="mdi-alert" :text="alert.text" title="Ein Fehler ist aufgetreten" class="authentication-alert"></v-alert>
</template>

<script>
import Sidebar from '../../components/Authentication/Sidebar.vue'

export default {
  name: 'Authentication-View',
  components: {
    Sidebar,
  },
  data() {
    return {
      email: "",
      password: "",
      remember_device: false,

      signin_process: false,

      alert: {
        show: false,
        text: "",
      }
    }
  },
  methods: {
    GetRoute(domaine) {
      switch(domaine) {
        case "salonmanager": 
          return this.$store.state.Config.Application.Salonmanager.StartURL
      }
    },
    async SignIn(route) {
      if(this.signin_process) {
        return;
      }

      if(!this.$validator.IsValidEmail(this.email)) {
        this.alert.show = true;
        this.alert.text = "Bitte gebe eine gültige E-Mail an.";

        return;
      }
      if(!this.$validator.HasMinLength(this.password, 1)) {
        this.alert.show = true;
        this.alert.text = "Bitte gebe ein anderes Passwort ein.";
        
        return;
      }

      try {
        this.$store.state.Config.Default.Pageloading = true
        this.signin_process = true;
        const response = await fetch(`${this.$store.state.Config.Url.Server}${route}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: this.email, password: this.password, remember_device: this.remember_device }),
        });

        if (response.ok) {
          const responseData = await response.json();
        
          this.$store.state.Config.Authentication.Data.Token = responseData.Token
          this.$store.state.Config.Authentication.Data.ExpiryDate = responseData.ExpiryDate
          this.$store.state.Config.Authentication.Data.SecondsToExpire = responseData.SecondsToExpire
          this.$store.state.Config.Authentication.Data.Admin = responseData.Admin

          this.$cookies.set(`${this.$store.state.Config.Cookies.Authentication}`, btoa(JSON.stringify(responseData)))

          this.$router.push(this.GetRoute(responseData.Domaine.toLowerCase()))

          this.signin_process = false;
          this.$store.state.Config.Default.Pageloading = false
        } else {
          console.log(await response.text())
          this.$emit("error", await response.text())
        }
      } catch (error) {
        this.signin_process = false;
        console.error('Error: ', error.message);
      }
    }
  },
}
</script>

<style scoped>
.authentication-alert {
  position:fixed;
  left:50%;
  top:50px;
  width:400px;
  margin-left:-200px
}
.authentication-button {
  text-transform: none !important;
  font-size: 16px;
  letter-spacing: 0.45px;
  border-radius: 5px;
  height:40px !important;
}
.text-h5 {
  font-size: 34px !important;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 5px;
}
.authentication-container,
.authentication-actions {
  display: flex;
  flex-direction: row;
}
.authentication-form {
  padding: 80px 0px 0px 80px;
  width: 500px;
}
.text-body-1{
  letter-spacing: 0px !important;
  margin-bottom: 30px;
  font-size: 18px !important;
  line-height: 26px;
  color: rgb(75, 75, 75);
  font-weight: 400;
}
.authentication-actions {
  margin-top: -10px;
  margin-bottom: 35px;
}
.authentication-textbox::placeholder {
  color: black !important;
}
.authentication-link {
  color: #1E88E5;
  text-decoration: none;
}
.authentication-link:hover {
  color: #1976D2;
  text-decoration: underline;
}
.authentication-checkbox{
  margin-left: -7px;
  margin-top: -10px;
}

@media(max-width: 680px) {
  .authentication-container{
    flex-direction: column;
  }
  .authentication-form{
    margin: 40px 31px 0px 31px;
    padding: 0px;
    width:calc(100% - 62px);
  }
}

</style>
