<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="add-register-open-btn" >Konto aktivieren</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Mitarbeiterprofil aktivieren">
                <v-card-text style="padding-bottom:20px">
                    Möchten Sie das Konto wieder Aktivieren? Nach dem bestätigen kann der Mitarbeiter mit seiner E-Mail-Adresse und dem Passwort wieder sich anmelden und auf das Konto zugreifen.

                    <div class="add-register-action">
                        <div class="width-100"></div>
                        <v-btn @click="Run(isActive)">Bestätigen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            registerName: "",
            error_message: ""
        }
    },
    methods: {
        async Run(isActive) {
            isActive.value = false
            this.$emit("activate-emplyoee")
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>