<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn class="add-register-open-btn" v-bind="activatorProps">Adressdaten</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Adressdaten einsehen">
                <v-card-text style="padding-bottom:5px">
                    <div style="position:relative">
                        <div style="background:transparent;width:100%;height:100%;position:absolute;z-index:1"></div>
                        <v-text-field style="margin-top:20px" v-model="city" label="Stadt..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <v-text-field v-model="street" label="Straße..." variant="outlined" class="add-register-textfield"></v-text-field>
                    </div>
                    
                    <div class="flex-direction-row" style="position:relative">
                        <div style="background:transparent;width:100%;height:100%;position:absolute;z-index:1"></div>
                        <v-text-field v-model="houseNumber" label="Hausnummer..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <div style="width:20px"></div>
                        <v-text-field v-model="postalCode" label="Postleizahl..." variant="outlined" class="add-register-textfield"></v-text-field>
                    </div>

                    <div class="add-register-action" style="margin-top: 30px !important;padding-bottom:20px">
                        <div class="width-100"></div>
                        <v-btn @click="isActive.value = false" >schließen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddCustomer-Component", 
    data() {
        return {
            addAdressDataDialog: false,

            city: "",
            street: "",
            houseNumber: "",
            postalCode: "",

            error_message: ""
        }
    },
    props: {
        customer: {
            Type: Object,
            required: true
        }
    },
    mounted() {
        this.city = this.customer.City;
        this.street = this.customer.Street;
        this.houseNumber = this.customer.HouseNumber;
        this.postalCode = this.customer.PostalCode;
    },
}
</script>

<style scoped>
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:0px;
}
</style>