<template>
    <div class="pageloading-container">
        <center>
            <v-progress-circular color="primary" indeterminate :size="45"></v-progress-circular>
        </center>
    </div>
</template>

<script>
export default {
    name: "Pageloading-Component"
}
</script>

<style scoped>
.pageloading-container {
    position: fixed;
    left: 0%;
    top: 0%;
    margin: 0px;
    padding:0px;
    width:100%;
    height:100vh;
    background:rgba(252, 252, 252, 0.6);
    backdrop-filter: blur(2px);
    z-index: 99999;
}
.pageloading-container > center {
    margin-top: 30vh;
}
</style>