<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn class="ministration-edit-btn" v-bind="activatorProps" icon="mdi-pen"></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Register bearbeiten">
                <v-card-text style="padding-bottom:20px">
                    <v-text-field v-model="registerName" label="Registername..." variant="outlined" class="add-register-textfield"></v-text-field>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red">{{ error_message }}</p>

                    <div class="add-register-action">
                        <v-btn @click="DeleteRegister(isActive)">Löschen</v-btn>
                        <div class="width-100"></div>
                        <v-btn @click="EditRegister(isActive)">Umbenennen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            registerName: "",
            error_message: ""
        }
    },
    props: {
        registerId: {
            type: String,
            required: true
        },
        registers: {
            type: Array,
            required: true
        }
    },
    mounted() {
        const register = this.registers.find(e => e.id == this.registerId) 
        if(register !== null && register !== undefined) {
            this.registerName = register.name;
        }
    },
    methods: {
        async DeleteRegister(isActive) {
            isActive.value = false
            this.$emit("delete-register", { registerId: this.registerId })
        },
        async EditRegister(isActive) {
            if(!this.$validator.IsBetweenLength(this.registerName, 1, 255)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000010", "DE")
                return;
            }

            if(!this.$validator.TextOnlyCharctersAndSpace(this.registerName)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000011", "DE")
                return;
            }

            this.error_message = "";
            isActive.value = false
            this.$emit("edit-register", this.registerName)
        }
    }
}
</script>

<style scoped>
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>