<template>
  <!-- Authentication Handler -->
  <AuthenticationHandler @authentication-loaded="loaded = true" />

  <!-- Error Handler -->
  <v-alert color="red" icon="mdi-alert" v-if="alert.show" :text="alert.text" title="Ein Fehler ist aufgetreten" class="EliasScharf_AlertBox"></v-alert>

  <!-- Pageloading -->
  <Pageloading :class="{'EliasScharf_Hide': !this.$store.state.Config.Default.Pageloading}" />

  <!-- RouterView -->
  <router-view @error="Error" v-if="loaded"></router-view>
</template>

<script>
import RouterView from "vue-router"
import AuthenticationHandler from "./components/Authentication/AuthenticationHandler.vue"
import Pageloading from "./components/Default/Pageloading.vue"

export default {
  name: 'App',
  components: { RouterView, AuthenticationHandler, Pageloading },
  data() {
    return {
      loaded: false,

      alert: {
        show: false,
        text: "",
      }
    }
  },
  methods: {
    async Error(code) {
      this.alert.text = await this.$errorcodes.GetErrorMessage(code, "DE")
      this.alert.show = true;

      setTimeout(() => {
        this.alert.show = false;
        this.alert.text = "";
      }, 3500)
    }
  },
}
</script>

<style>
@font-face {
  font-family: FigtreeVariable;
  src: url(./assets/typefaces/Figtree-Variable.ttf);
}
@font-face {
  font-family: LoraMedium;
  src: url(./assets/typefaces/Lora-Medium.ttf);
}
@font-face {
  font-family: WorkSansMedium;
  src: url(./assets/typefaces/WorkSans-Medium.ttf);
}

* {
  font-family: FigtreeVariable !important;
}

.typeface-figtree {
  font-family: FigtreeVariable !important;
}
.typeface-loramedium {
  font-family: LoraMedium !important;
}
.typeface-worksansmedium {
  font-family: WorkSansMedium !important;
}

.EliasScharf_AlertBox {
  max-width: 500px !important;
  position: fixed !important;
  margin-left: 50%;
  top: 50px;
  left: -250px;
  z-index: 999999999 !important;
}
.flex-direction-row {
  display: flex;
  flex-direction: row;
}
.width-100 {
  width: 100%;
}
.EliasScharf_Hide {
  display: none !important;
}
.app-layout {
  margin-top:60px;
  height: calc(100vh - 60px);
  display:flex;
  flex-direction: row;
  width: 100%;
}
.app-layout > div:first-child {
  border-right: 1px solid lightgrey;
  box-shadow: none;
}
.app-layout > div:last-child {
  width: 100%;
  height: 100%;
}


@media(max-width: 1100px) {
  .app-layout > div:last-child {
    margin-left: 0px !important;
  }
}

@media(max-width: 600px) {
  .v-overlay__content {
    width: 100% !important;
    min-width: 100% !important;
    bottom: 0% !important;
    left: 0% !important;
    margin: 0px !important;
  }
  .v-overlay__content > .v-card {
    padding-bottom: 20px !important;
  }
}

</style>
