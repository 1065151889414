<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="add-register-open-btn" prepend-icon="mdi-plus">mitarbeiterprofil</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Mitarbeiterprofil erstellen">
                <v-card-text style="padding-bottom:20px">
                    Geben Sie bitte alle nötigen Informationen zum erstellen eines Mitarbeiterprofiles ein.

                    <div class="flex-direction-row" style="margin-top:20px">
                        <v-text-field v-model="firstname" label="*Vorname..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <div style="width:20px"></div>
                        <v-text-field v-model="lastname" label="*Nachname..." variant="outlined" class="add-register-textfield"></v-text-field>
                    </div>
                    <v-text-field v-model="email" type="email" label="E-Mail..." variant="outlined" class="add-register-textfield"></v-text-field>
                    <v-text-field v-model="color" type="color" label="Erkennungsfarbe..." variant="outlined" class="add-register-textfield"></v-text-field>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red;padding-top:20px">{{ error_message }}</p>

                    <div class="add-register-action" style="margin-top: 30px !important">
                        <div class="width-100"></div>
                        <v-btn @click="AddEmployee(isActive)">Erstellen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddCustomer-Component", 
    data() {
        return {
            firstname: "",
            lastname: "",
            email: "",
            color: "#000000",

            error_message: ""
        }
    },
    methods: {
        async AddEmployee(isActive) {

            if(!this.$validator.TextOnlyCharacters(this.firstname)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000031", "DE")
                return;
            }
            if(!this.$validator.TextOnlyCharacters(this.lastname)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000032", "DE")
                return;
            }
            if(!this.$validator.IsBetweenLength(this.firstname, 1, 255)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000033", "DE")
                return;
            }
            if(!this.$validator.IsBetweenLength(this.lastname, 1, 255)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000034", "DE")
                return;
            }
            if(!this.$validator.IsValidEmail(this.email)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000021", "DE")
                return;
            }
            if(!this.$validator.HasMinLength(this.email, 1)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000021", "DE")
                return;
            }
            if(!this.$validator.HasMinLength(this.email, 1)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000021", "DE")
                return;
            }
            if(!this.$validator.HasLength(this.color, 7)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000035", "DE")
                return;
            }

            isActive.value = false
            this.error_message = "";
            
            this.$emit("create-employee", {
               firstname: this.firstname,
               lastname: this.lastname,
               email: this.email,
               color: this.color
            })
        }
    }
}
</script>

<style scoped>
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:0px;
}
</style>