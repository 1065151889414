<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div class="content">
                <div class="calendar-navigtion">
                    <div>
                        <v-btn @click="GetPreviousDays" icon="mdi-arrow-left" style="box-shadow:none;font-size: 14px;height:35px;width:35px;margin:13px 0px 0px 10px"></v-btn>
                        <v-btn @click="GetTodayDays" style="box-shadow:none;font-size: 14px;height:35px;padding-left: 10px;padding-right: 10px;margin-left: 5px;margin-right: 5px;margin-top: 15px;">Heute</v-btn>
                        <v-btn @click="GetNextDays" icon="mdi-arrow-right" style="box-shadow:none;font-size: 14px;height:35px;width:35px;margin:13px 0px 0px 0px"></v-btn>
                        <p>{{ days[0].name }},&nbsp;{{ days[0].date }}</p>
                    </div>
                    <div :class="{'verySmall': mobileView}">
                        <p :class="{'EliasScharf_Hide': mobileView}">{{ days[1].name }},&nbsp;{{ days[1].date }}</p>
                        <AddEvent v-if="createEvent" :date="days[0].date" :employees="employees" :ministrations="ministrations" :registers="registers" :customers="customers" @add-event="AddEvent" />
                    </div>
                </div>
                <div class="calendar-container" id="calendar-container">
                    <div class="sidebar" id="sidebar"> 
                        <div class="time-container" v-for="time in times" :key="time.id"> <p>{{ time }}</p> <div class="line"></div> </div>
                    </div>
                    <div class="calendar" id="calendar" style="width:100%;position: relative;">
                        <div class="day" id="day" style="margin-left:-15px;border:none">
                            <div v-for="event in days[0].events" class="event" :id="'Event_'+event.event.Id" @click="SelectEvent(event.event.Id, true)" :key="event.id" :style="{
                                position: 'absolute',
                                top: event.style.top + 'px',
                                left: event.style.left + 'px',
                                width: event.style.width + 'px',
                                height: event.style.height + 'px',
                                background: GetBackgroundColorOfEvent(event.event.EmployeeId) + ' !important'
                                }">
                                <div :class="{'EliasScharf_Hide': event.event.Status !== '2'}" class="stripes"></div>
                                <p v-if="event.event.Firstname !== null && event.event.Firstname !== undefined &&  event.event.Lastname !== null && event.event.Lastname !== undefined">{{ event.event.Firstname }} {{ event.event.Lastname }}</p>
                                <p v-if="event.event.CustomerId !== '' && event.event.CustomerId !== null">{{ GetCustomerInfoById(event.event.CustomerId) }}</p>
                                <p v-if="event.event.FamiliyName !== null ">Familie {{ event.event.FamiliyName }}</p>
                            </div>

                            <div id="timeline"></div>
                        </div>
                        <div class="day" :class="{'EliasScharf_Hide': mobileView}">
                            <div v-for="event in days[1].events" class="event" :id="'Event_'+event.event.Id" :key="event.id" @click="SelectEvent(event.event.Id, false)" :style="{
                                position: 'absolute',
                                top: event.style.top + 'px',
                                left: event.style.left + 'px',
                                width: event.style.width + 'px',
                                height: event.style.height + 'px',
                                background: GetBackgroundColorOfEvent(event.event.EmployeeId) + ' !important'
                                }">
                                <div :class="{'EliasScharf_Hide': event.event.Status !== '2'}" class="stripes"></div>
                                <p v-if="event.event.Firstname !== null && event.event.Firstname !== undefined &&  event.event.Lastname !== null && event.event.Lastname !== undefined">{{ event.event.Firstname }} {{ event.event.Lastname }}</p>
                                <p v-if="event.event.CustomerId !== '' && event.event.CustomerId !== null">{{ GetCustomerInfoById(event.event.CustomerId) }}</p>
                                <p v-if="event.event.FamiliyName !== null ">Familie {{ event.event.FamiliyName }}</p>

                            </div>

                            <div id="timeline"></div>
                        </div>
                    </div>

                    <!-- VIEW EVENT -->
                    <ViewEvent @delete="DeleteEvent" @accept-event="Accept" @update="Update" ref="viewEvent" :allMinistrations="ministrations" :customers="customers" :events="events" :employees="employees" :assigned_ministrations="assigned_ministrations" />
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

import AddEvent from '../../../components/Applications/Salonmanager/AddEvent.vue'
import ViewEvent from '../../../components/Applications/Salonmanager/ViewEvent.vue'

export default {
    name: "Salonmanager_Calenadar-View",
    components: { Header, Navigation, AddEvent, ViewEvent },
    data() {
        return {
            events: [],
            times: [],

            viewEvent:false,
            createEvent: true,
            mobileView: false,

            days: [
                {
                    date: "",
                    name: "",
                    events: [],
                    maxOverlapping: 0,
                    eventWidth: 0,
                },
                {
                    date: "",
                    name: "",
                    events: [],
                    maxOverlapping: 0,
                    eventWidth: 0,
                }
            ],
            firstTime: true,

            ministrations: [],
            registers: [],
            customers: [],
            employees: [],

            view_event: {
                Id: "", 
                CorporationId: "", 
                CustomerId: "", 
                EmployeeId: "", 
                Type: "", 
                Firstname: "", 
                Lastname: "", 
                Phone: "", 
                Date: "", 
                StartTime: "", 
                EndTime: "", 
                Description: "", 
                Status: "", 
                Creator: "", 
                Created: "", 
                FamiliyName: "", 
                FamilyMembers: "", 
            },  

            view_event_customer: {},
            view_event_employee: {},

            assigned_ministrations: []
        }
    },
    async mounted() {
        await this.LoadInfos() 
        await this.RequestAllEvents();
        this.times = await this.GenerateTimeSidebar();

        if(window.innerWidth < 800) {
            this.mobileView = true;
        }
    },
    methods: {
        GetBackgroundColorOfEvent(employeeId) {
            if(employeeId !== null && employeeId !== undefined) {
                const employee = this.employees.find(e => e.Id == employeeId)

                if(employee !== null && employee !== undefined) {
                    return `${employee.Color}`
                } else {
                    return `#1976D2`
                }
            } else {
                return `#1976D2`
            }
        },
        async Update(id, familyName, familyMembers,ministrations,CustomerId, EmployeeId, Type, Firstname, Lastname, Email, Phone, Date, StartTime, EndTime, Description) {
            var dateToLoad =null

            var ele = this.days[0].events.find(e => e.event.Id == id.id.id)
            var ele1 = this.days[1].events.find(e => e.event.Id == id.id.id)

            if(ele !== undefined) {
                dateToLoad = String(ele.event.Date).split('T')[0]
            }
            if(ele1 !== undefined) {
                dateToLoad =  this.GetNextDay(this.GetDayDateTwoDaysBefore(String(ele1.event.Date).split('T')[0]))
            }

            this.$store.state.Config.Default.Pageloading = true
            const data = await this.SendPost("/salonmanager/update-event", {id: id, familyName: familyName, familyMembers: familyMembers,ministrations: ministrations,CustomerId: CustomerId, EmployeeId:EmployeeId, Type:Type, Firstname:Firstname, Lastname:Lastname, Email: Email, Phone: Phone, Date:Date, StartTime:StartTime, EndTime: EndTime, Description: Description})

            if(data.data.assignedMinistrations.length !== 0 && data.data.assignedMinistrations !== undefined && data.data.assignedMinistrations !== null) {
                this.assigned_ministrations = data.data.assignedMinistrations
            }
         
            this.FillEvents(data.data.events)
            setTimeout(() => {
                this.LoadCalendarInfo(dateToLoad);
            }, 100)
        },
        async Accept(id) {
            this.$store.state.Config.Default.Pageloading = true
            const data = await this.SendPost("/salonmanager/accept-event", {id: id})

            if(data.data.assignedMinistrations.length !== 0 && data.data.assignedMinistrations !== undefined && data.data.assignedMinistrations !== null) {
                this.assigned_ministrations = data.data.assignedMinistrations
            }
         
            this.FillEvents(data.data.events)
        },
        async DeleteEvent(id) {
            this.$store.state.Config.Default.Pageloading = true
            const data = await this.SendPost("/salonmanager/delete-event", {id: id})

            if(data.data.assignedMinistrations.length !== 0 && data.data.assignedMinistrations !== undefined && data.data.assignedMinistrations !== null) {
                this.assigned_ministrations = data.data.assignedMinistrations
            }
         
            this.FillEvents(data.data.events)
        },
        async SelectEvent(id) {
            this.$refs.viewEvent.Open(id);
        },
        async LoadInfos() {
            this.$store.state.Config.Default.Pageloading = true

            this.customers = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'get-customers', { hash: "_" })
            var registers = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'request-registers', { hash: "_" })
            var ministrations = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'request-ministrations', { hash: "_" })
            this.employees = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'get-employees', { hash: "_" })
            
            setTimeout(() => {
                if(this.customers !== undefined && this.customers !== null) {
                    this.customers.forEach(customer => {
                        if(customer.Birthday !== null) {
                            customer.Birthday = String(String(customer.Birthday).split('T')[0])
                        }
                    })
                }
            }, 10)

            if(registers !== undefined && registers !== null) {
                registers.forEach(register => {
                    this.registers.push( { name: register.Name, id: register.Id } )
                })
            }

            if(ministrations !== undefined && ministrations !== null) {
                ministrations.forEach(ministration => {
                    this.ministrations.push(ministration)
                })
            }

            this.$store.state.Config.Default.Pageloading = false
        },
        GetCustomerInfoById(id) {
            try {
                const customer = this.customers.find(e => e.Id == id)
                return `${customer.Firstname} ${customer.Lastname}`
            } catch {
                return `Nutzer existiert nicht mehr.`
            }
        },
        GetPositionByTime() {
            var date = new Date()
            var spaceToTop = ((date.getHours() * 60) + date.getMinutes() ) *2

            var allTimelines = document.querySelectorAll("#timeline")

            allTimelines.forEach(timeline => {
                timeline.setAttribute("style", `margin-top: ${spaceToTop}px;` )
            })

            if(this.firstTime) {
                this.firstTime = false;
                document.getElementById("calendar-container").scrollTop = (spaceToTop-200)
            }

            setTimeout(() => {
                this.GetPositionByTime();
            }, 15000)
        },
        async GetPreviousDays() {
            if(this.mobileView) {
                const usDate = await this.$datetime.GetUSDateFromEU(this.days[0].date)
                this.LoadCalendarInfo(this.GetNextDay(this.GetDayDateTwoDaysBefore(usDate)));
            } else {
                const usDate = await this.$datetime.GetUSDateFromEU(this.days[0].date)
                this.LoadCalendarInfo(this.GetDayDateTwoDaysBefore(usDate));
            }
        },
        async GetNextDays() {
            if(this.mobileView) {
                this.LoadCalendarInfo(await this.$datetime.GetUSDateFromEU(this.days[1].date));
            } else {
                const usDate = await this.$datetime.GetUSDateFromEU(this.days[1].date)
                this.LoadCalendarInfo(this.GetNextDay(usDate));
            }
        },
        GetTodayDays() {
            const today = new Date();
            this.LoadCalendarInfo(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`);
        },
        async LoadCalendarInfo(date) {
            this.days[0].events = []
            this.days[1].events = []

            this.$store.state.Config.Default.Pageloading = true

            const result = this.getNextDayInfo(date);

            this.days[0].date = result.currentDateFormat
            this.days[0].name = result.currentDayName

            this.days[1].date = result.nextDateFormat
            this.days[1].name = result.nextDayName

            for(var x = 0; x < this.events.length; x++) {
                if(String(this.events[x].event.Date).split('T')[0] == this.$datetime.GetUSDateFromEU(this.days[0].date)) {
                    this.days[0].events.push(this.events[x])
                }

                if(String(this.events[x].event.Date).split('T')[0] == this.$datetime.GetUSDateFromEU(this.days[1].date)) {
                    this.days[1].events.push(this.events[x])
                }
            }

            if(this.days[0].events.length !== 0) {
                this.days[0].maxOverlapping =  this.CalcualteMaxWidth(this.days[0].events)
                for(var u = 0; u < this.days[0].events.length; u++) {
                    this.days[0].events[u].style = this.GetStyleByEvent( this.days[0].events[u], this.days[0].maxOverlapping, true)
                }
            }

            if(this.days[1].events.length !== 0) {
                this.days[0].maxOverlapping =  this.CalcualteMaxWidth(this.days[1].events)
                for(var y = 0; y < this.days[1].events.length; y++) {
                    this.days[1].events[y].style = this.GetStyleByEvent( this.days[1].events[y], this.days[0].maxOverlapping, false)
                }
            }

            var date2 = new Date()
            var spaceToTop = ((date2.getHours() * 60) + date2.getMinutes() ) *2
            document.getElementById("calendar-container").scrollTop = (spaceToTop-200)

            setTimeout(async () => {
                const isFinished = await this.checkForOverlap();
                this.GetPositionByTime();
              
                if(isFinished) {
                    this.$store.state.Config.Default.Pageloading = false
                }
            }, 1)
        },
        AddTwoHours(dateString, hoursToAdd) {
            const date = new Date(dateString);
            date.setHours(date.getHours() + hoursToAdd);
            return date.toISOString();
        },
        GetDayDateTwoDaysBefore(dateStr) {
            const [year, month, day] = dateStr.split('-').map(Number);

            const currentDate = new Date(year, month - 1, day); 

            const twoDaysBefore = new Date(currentDate);
            twoDaysBefore.setDate(twoDaysBefore.getDate() - 2);

            const twoDaysBeforeDate = twoDaysBefore.getDate();
            const twoDaysBeforeMonth = twoDaysBefore.getMonth() + 1;
            const twoDaysBeforeYear = twoDaysBefore.getFullYear();

            return `${twoDaysBeforeYear}-${twoDaysBeforeMonth < 10 ? '0' : ''}${twoDaysBeforeMonth}-${twoDaysBeforeDate < 10 ? '0' : ''}${twoDaysBeforeDate}`;
        },
        GetNextDay(dateStr) {
            const [year, month, day] = dateStr.split('-').map(Number);
            const currentDate = new Date(year, month - 1, day); 

            const nextDay = new Date(currentDate);
            nextDay.setDate(nextDay.getDate() + 1);

            const nextDayDate = nextDay.getDate();
            const nextMonth = nextDay.getMonth() + 1; 
            const nextYear = nextDay.getFullYear();

            return `${nextYear}-${nextMonth < 10 ? '0' : ''}${nextMonth}-${nextDayDate < 10 ? '0' : ''}${nextDayDate}`;
        },
        getNextDayInfo(dateStr) {
            const currentDate = new Date(dateStr);

            const currentDayName = new Intl.DateTimeFormat('de-DE', { weekday: 'long' }).format(currentDate);

            const currentDayDate = currentDate.getDate();
            const currentMonth = currentDate.getMonth() + 1; 
            const currentYear = currentDate.getFullYear();

            const nextDay = new Date(currentDate);
            nextDay.setDate(nextDay.getDate() + 1);

            const nextDayName = new Intl.DateTimeFormat('de-DE', { weekday: 'long' }).format(nextDay);

            const nextDayDate = nextDay.getDate();
            const nextMonth = nextDay.getMonth() + 1; 
            const nextYear = nextDay.getFullYear();

            const currentDateFormat = `${currentDayDate < 10 ? '0' : ''}${currentDayDate}.${currentMonth < 10 ? '0' : ''}${currentMonth}.${currentYear}`;
            const nextDateFormat = `${nextDayDate < 10 ? '0' : ''}${nextDayDate}.${nextMonth < 10 ? '0' : ''}${nextMonth}.${nextYear}`;

            return {
                currentDayName,
                currentDateFormat,
                nextDayName,
                nextDateFormat
            };
        },
        checkForOverlap() {
            if(this.days[0].events !== undefined && this.days[0].events !== null) {
                for(var i = 0; i < this.days[0].events.length; i++) {
                    const currentRect = this.getBoundingRect( this.days[0].events[i].style);
                    var position = 0;
                    
                    for(var x = (i-1); x >= 0; x--) {
                        const prevToCheckRect = this.getBoundingRect(this.days[0].events[x].style);

                        if (currentRect.right > prevToCheckRect.left &&  currentRect.left < prevToCheckRect.right && currentRect.bottom > prevToCheckRect.top && currentRect.top < prevToCheckRect.bottom) {
                            
                            position = position + this.days[0].eventWidth 
                        } 
                    }

                    const elementStyle = document.getElementById(`Event_${this.days[0].events[i].event.Id}`).getAttribute("style")
                    document.getElementById(`Event_${this.days[0].events[i].event.Id}`).setAttribute("style", `${elementStyle};left: ${position}px;`)
                }
            }

            if(this.days[1].events !== undefined && this.days[1].events !== null) {
                for(var y = 0; y < this.days[1].events.length; y++) {
                    const currentRect = this.getBoundingRect( this.days[1].events[y].style);
                    var position2 = 0;
                    var hasPositionFound = false;
                    
                    for(var z = (y-1); z >= 0; z--) {
                        const prevToCheckRect = this.getBoundingRect(this.days[1].events[z].style);

                        if(this.days[1].events[z].event.Id !== this.days[1].events[y].event.Id && !hasPositionFound) {
                            if (currentRect.right > prevToCheckRect.left &&  currentRect.left < prevToCheckRect.right && currentRect.bottom > prevToCheckRect.top && currentRect.top < prevToCheckRect.bottom) {
                                position2 = position2 + this.days[1].eventWidth
                            } else {
                                hasPositionFound = true;
                            }
                        }
                    }

                    const elementStyle = document.getElementById(`Event_${this.days[1].events[y].event.Id}`).getAttribute("style")
                    document.getElementById(`Event_${this.days[1].events[y].event.Id}`).setAttribute("style", `${elementStyle};left: ${position2}px`)
                }
            }

            return true;
        },
        getBoundingRect(attributes) {
            const { top, left, width, height } = attributes;
            return {
                top,
                left,
                right: left + width,
                bottom: top + height
            };
        },
        GenerateTimeSidebar() {
            const times = [];

            for (let hours = 0; hours < 24; hours++) {
                for (let minutes = 0; minutes < 60; minutes += 15) {
                    times.push(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
                }
            }

            return times;
        },
        GetStyleByEvent(eventInfo, maxOverlapping, day) {
            var height = Number(this.$calculator.GetMinutesBetweenTime(eventInfo.event.StartTime, eventInfo.event.EndTime)) * 2
    
            var width;
            if(maxOverlapping == 0) {
                width = document.getElementById("day").offsetWidth
            } else {
                width = document.getElementById("day").offsetWidth * (1 /(maxOverlapping))
            }
            
            if(day) {
                this.days[0].eventWidth = width
            } else {
                this.days[1].eventWidth = width
            }

            var left = 0
            var top = ((Number(eventInfo.event.StartTime.split(':')[0]) * 60) + Number(eventInfo.event.StartTime.split(':')[1])) * 2
            return { height: height, width: width, top: top, left: left }
        },
        CalcualteMaxWidth(events) {
            let overlappingMax = 0;
            for(var i = 0; i < events.length; i++) {
                var list =[]
                var overlappingTimes = 0;
                for(var x = 0; x < events.length; x++) {
                   
                    const status2 = this.$validator.IsTimeBetweenOtherTimes(events[i].event.EndTime, events[x].event.StartTime, events[x].event.EndTime)

                    if(status2) {
                        overlappingTimes++;
                    }
                    list.push(`${events[i].event.Id}`)
                }
                if(overlappingTimes > overlappingMax) {
                    overlappingMax = overlappingTimes
                }
            }
 
            return overlappingMax;
        },
        async RequestAllEvents() {
            this.$store.state.Config.Default.Pageloading = true
            const data = await this.SendPost("/salonmanager/request-events")

            if(data !== undefined) {
                if(data.data.assignedMinistrations !== undefined && data.data.assignedMinistrations !== null) {
                    if(data.data.assignedMinistrations.length !== 0) {
                        this.assigned_ministrations = data.data.assignedMinistrations
                    }
                }

                this.FillEvents(data.data.events)
                this.$store.state.Config.Default.Pageloading = false
            }
        },
        async AddEvent(eventInfo) {
            this.$store.state.Config.Default.Pageloading = true
            const data = await this.SendPost("/salonmanager/create-event", eventInfo)

            if(data.data.assignedMinistrations.length !== 0 && data.data.assignedMinistrations !== undefined && data.data.assignedMinistrations !== null) {
                this.assigned_ministrations = data.data.assignedMinistrations
            }
         
            this.FillEvents(data.data.events)
            this.createEvent = false;
            setTimeout(() => {
                this.createEvent = true;
            }, 1)
        },
        async SortEventArray(events) {
            function compareStartTime(event1, event2) {
                const startTime1 = event1.event.StartTime.split(':').map(num => parseInt(num));
                const startTime2 = event2.event.StartTime.split(':').map(num => parseInt(num));
                
                if (startTime1[0] !== startTime2[0]) {
                    return startTime1[0] - startTime2[0];
                }
                if (startTime1[1] !== startTime2[1]) {
                    return startTime1[1] - startTime2[1];
                }
                if (startTime1[2] !== startTime2[2]) {
                    return startTime1[2] - startTime2[2];
                }

                return 0;
            }

            events.sort(compareStartTime);

            return events;
        },
        async FillEvents(events) {
            this.events = []
            if(events.length !== 0 && events !== null && events !== undefined) {
                for(var i = 0; i < events.length; i++) {
                    this.events.push(
                        {
                            event: events[i],
                            style: null,
                        }
                    )
                    this.events[i].event.Date = this.AddTwoHours(this.events[i].event.Date, 2)
                }
            }

            this.events = await this.SortEventArray(this.events)

            this.GetTodayDays();
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$emit("error", await response.text())
                    this.$store.state.Config.Default.Pageloading = false
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    },
}
</script>

<style scoped>
@media(max-width: 800px) {
    .day {
        width: calc(100vw - 90px) !important;
    }
}
.stripes{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    background: repeating-linear-gradient(
        -45deg,
        #ffffff00,
        rgb(255,255,255,.3) 5px,
        rgb(255,255,255,.3) 5px,
        rgb(255,255,255,.3) 10px
    );
}
#timeline{
    height: 2px;
    width: 150vw;
    position: absolute;
    z-index: 10;
    margin-left: -5px !important;
    background-color: red;
}
.verySmall{
    width:0px;
    height:0px;
}
.calendar-navigtion{
    height:50px;
    display: flex;
    flex-direction: row;
}
.calendar-navigtion > div {
    display: flex;
    width: calc(50% + 30px) !important;
    min-width: calc(50% + 30px) !important;
}
.calendar-navigtion > div > p {
    padding-left: 10px;
    margin: 0px;
    line-height: 60px;
    font-size: 16px;
}
.time-container {
    height:30px;
    min-height: 30px;;
    border-bottom: 1px solid lightgrey;
    width: 60px;
    min-width: 60px;
    text-align: center;
    position: relative;
}
.time-container > p {
    position: absolute;
    line-height: 0px;
    width: 45px;
    text-align: center;
    margin-left: 7.5px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    margin-top: -2px;
    background-color: white;
    z-index: 4;
    height: 30px;
}
.time-container > .line {
    height: 30px;
    margin: 0px;
    position: absolute;
    width: calc(100vw);
    z-index: 1;
    margin-left: 60px;
    border-bottom: 1px solid rgba(211, 211, 211, 1);
    cursor: pointer;
}
.time-container > .line:hover {
    background-color: rgba(211, 211, 211, 0.35);
}
.content {
    display: flex;
    flex-direction: column;
}
.content > .calendar-navigtion {
    height: 60px;
    min-height: 60px;
    border-bottom: 1px solid lightgrey;
}
.calendar-container {
    margin: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    display: flex;
    height: calc(100vh - 100px);
    overflow: scroll;
    overflow-x: hidden;
    flex-direction: row;
}
.sidebar {
    border-right: 1px solid #ccc;
    height: 2880px;
}
.time {
    margin-bottom: 10px;
}
.calendar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.calendar > div:first-child {
    border-right: 1px solid lightgrey;
}
.calendar > div:last-child {
    border-left: 1px solid lightgrey;
}
.day {
    position: relative;
    width: calc(100% - 30px);
    height: 2880px;
    min-height: 2880px;
    height: 100%;
}
.events {
    flex-grow: 1;
    position: relative;
}
.event {
    background-color: #1976D2;
    transition: 100ms;
    border-radius: 5px;
    cursor: pointer;
    z-index: 3;
    border: 1px solid white;
    color: white;
}
.event:hover {
    background: #1565C0;
}
</style>
