<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="add-register-open-btn" >Bearbeiten</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Feiertag bearbeiten">
                <v-card-text style="padding-bottom:20px">
                    <v-text-field v-model="holidayName" label="Name..." variant="outlined" class="add-register-textfield"></v-text-field>
                    <v-text-field v-model="holidayDate" label="Datum..." type="date" variant="outlined" class="add-register-textfield"></v-text-field>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red">{{ error_message }}</p>

                    <div class="add-register-action">
                        <v-btn @click="deleteModal = true">löschen</v-btn>
                        <div class="width-100"></div>
                        <v-btn @click="Update(isActive)">Speichern</v-btn>
                    </div>

                    <v-dialog max-width="500" v-model="deleteModal">
                        <v-card title="Feiertag löschen?">
                            <v-card-text style="padding-bottom:20px">
                               Möchtest du den Feiertag wirklich aus Ihren Kalender vollständig löschen?

                                <div class="add-register-action" style="margin-top: 30px !important">
                                    <v-btn @click="this.deleteModal = false" >Abbrechen</v-btn>
                                    <div class="width-100"></div>
                                    <v-btn @click="Delete(isActive)">Bestätigen</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            deleteModal: false,

            holidayName: "",
            holidayDate: "",
            error_message: "",
            id: "",
        }
    },
    props: {
        holiday: {
            Type: Object, 
        }
    },
    mounted() {
        this.id = this.holiday.Id
        this.holidayName = this.holiday.Name
        this.holidayDate = String(this.holiday.Date).split('T')[0]
    },
    methods: {
        async Update(isActive) {
            if(await this.$validator.TextHasLegalLetters(this.holidayName) == false) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000056", "DE")
                return 
            }
            if(await this.$validator.IsValidDateInUSFormat(this.holidayDate) == false) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000057", "DE")
                return 
            }

            isActive.value = false
            this.error_message = "";
            
            this.$emit("update-holiday", {name: this.holidayName, date: this.holidayDate, id: this.id})
        },
        async Delete(isActive) {
            isActive.value = false
            this.error_message = "";
            this.deleteModal= false;
            this.$emit("delete-holiday", {id: this.id})
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>