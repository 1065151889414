<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div>
                <div class="app-layout-header-controler">
                    <div style="width:100%"></div>
                    <AddCustomer @create-customer="CreateCustomer" />
                </div>
                
                <v-table>
                    <thead>
                        <tr class="app-layout-table">
                            <th class="text-left">vorname</th>
                            <th class="text-left">nachname</th>
                            <th class="text-left">email</th>
                            <th class="text-left">telefon</th>
                            <th class="text-left">geburtstag</th>
                            <th class="text-left">Adresse</th>
                            <th class="text-left">aktion</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="app-layout-table-item" v-for="customer in customers" :key="customer.id">
                            <td>{{ customer.Firstname }}</td>
                            <td>{{ customer.Lastname }}</td>
                            <td v-if="customer.Email !== 'null' && customer.Email !== null && customer.Email !== undefined">{{ customer.Email }}</td>
                            <td v-else>-</td>
                            <td v-if="customer.Phone !== 'null' && customer.Phone !== null && customer.Phone !== undefined">{{ customer.Phone }}</td>
                            <td v-else>-</td>
                            <td v-if="customer.Birthday !== 'null' && customer.Birthday !== null && customer.Birthday !== undefined">{{ $datetime.GetEUDateFromUS(customer.Birthday) }}</td>
                            <td v-else>-</td>
                            <td style="width:100px" v-if="customer.City !== 'null' && customer.City !== null && customer.City !== undefined">  <ViewCustomerAdress :customer="customer" v-if="customer.City !== null && customersLoaded" /> </td>
                            <td style="width:100px" v-else>-</td>
                            <td style="width:100px"> 
                                <EditCustomer v-if="customersLoaded" :customer="customer" @delete-customer="DeleteCustomer" @update-customer="UpdateCustomer" />
                            </td>
                        </tr>
                    </tbody>
                </v-table>

                <p class="ministration-notify-no-ministrations" :class="{'EliasScharf_Hide': !CheckIfMinistrationsAreExising()}">Keine Kunden gefunden.</p>
            </div>
        </div>
    </v-layout>
</template>

<script>
import CryptoJS from 'crypto-js';

import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

import EditCustomer from '../../../components/Applications/Salonmanager/EditCustomer.vue'
import AddCustomer from '../../../components/Applications/Salonmanager/AddCustomer.vue'
import ViewCustomerAdress from '../../../components/Applications/Salonmanager/ViewCustomerAdress.vue'

export default {
    name: "Salonmanager_Ministrations-View",
    components: { Header, Navigation, AddCustomer, EditCustomer, ViewCustomerAdress },
    data() {
        return {
            customersLoaded: false,
            customers: []
        }
    },
    async mounted() {
       await this.RequestAllCustomers()
       this.customersLoaded = true;
    },
    methods: {
        async DeleteCustomer(id) {
            const customers = await this.SendPost("/salonmanager/delete-customer", {customerID: id})
            this.FillCustomers(customers) 
        },
        async UpdateCustomer(customerData) {
            const customers = await this.SendPost("/salonmanager/update-customer", customerData)
            this.FillCustomers(customers) 
        },
        async RequestAllCustomers() {
            try {
                const customers = this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.Customers}`)
            
                if(customers == null || customers == undefined || customers.legnth == 0) {
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Customers}`, null)
                }
                
                let result = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'get-customers', { hash: CryptoJS.SHA256(JSON.stringify(customers)).toString() })
                
                if(result == true) {
                    this.FillCustomers(customers)
                } else {
                    const jsonResult = JSON.stringify(result)
            
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Customers}`, jsonResult)
                    this.FillCustomers(result)
                } 
            } 
            catch(error) {
                console.log(error)
            }
        },
        async CreateCustomer(customerData) {
            const customers = await this.SendPost("/salonmanager/create-customer", customerData)
            this.FillCustomers(customers) 
        },
        FillCustomers(customers) {
            if(typeof customers === "string") {
                return;
            }
            this.$store.state.Config.Default.Pageloading = true
            this.customers = []

            setTimeout(() => {
                this.customers = customers

                if(this.customers !== undefined && this.customers !== null) {
                    this.customers.forEach(customer => {
                        if(customer.Birthday !== null) {
                            customer.Birthday = String(String(customer.Birthday).split('T')[0])
                        }
                    })
                }

                this.$store.state.Config.Default.Pageloading = false
            }, 10)
        },
        CheckIfMinistrationsAreExising() {
            try {
                if(this.customers.length == 0) {
                    return true;
                } else {
                    return false;
                }
            } catch {
                return false;
            }
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    this.$emit("error", await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    }
}
</script>

<style scoped>
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.ministration-notify-no-ministrations {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.app-layout-header-controler {
    margin-bottom: 20px;
    padding-right:15px;
    padding-left: 15px;
    display:flex;
    flex-direction: row;
    padding-top:20px
}
.ministration-btn {
    height:40px;
    margin-left:15px
}
.ministration-select {
    width:260px;
    max-width: 260px;
    min-width: 260px
}
.app-layout-table {
    background: #1976D2;
    color:white;
    overflow: hidden;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
</style>
