<template>
    <v-card flat style="min-width: 100%;height:100%;position: absolute;top:0%;left:0%;z-index: 2;" class="edit-employee-container">
        <v-container fluid style="min-width: 100%;">
            <v-row class="child-flex" style="min-width: 100%;">
                <div style="min-width: 100%;">
                    <v-toolbar style="min-width: 100%;">
                        <v-btn class="hidden-xs-only" @click="CloseEmployeePage" icon>
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>

                        <v-toolbar-title>
                            <div class="flex-direction-row">
                                {{ firstname }} {{ lastname }}   

                                <div class="deactiviated-notification" :class="{'EliasScharf_Hide': employee.Status !== '2'}">Deaktiviert</div>
                            </div>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                    </v-toolbar>
                </div>
            </v-row>
        </v-container>
        <p class="text-subtitle-2" style="font-size: 15px !important;padding-left:15px;padding-top:10px">Kontoinformationen:</p>
        <v-table style="height:180px">
            <tbody>
                <tr class="app-layout-table-item">
                    <td>Vollständiger Name: {{ firstname }} {{ lastname }}</td>
                </tr>
                <tr class="app-layout-table-item">
                    <td>E-Mail-Adresse: {{ email }} </td>
                </tr>
                <tr class="app-layout-table-item" style="position: relative">
                    <td>Erkennungsfarbe:  <v-text-field style="position: absolute;width:100px;right:20px;top:5px" density="compact" v-model="color" type="color" variant="outlined"></v-text-field> </td>
                </tr>
            </tbody>
        </v-table>
        <div class="edit-employee-actions" style="width:calc(100% - 30px);padding-left:15px;margin-top:5px;" >
            <div style="margin-bottom:25px" :class="{'EliasScharf_Hide': employee.Status !== '1'}" class="edit-employee-actions flex-direction-row">
                <div style="width:100%"></div>
                <v-btn @click="SaveChanges()">speichern</v-btn>
            </div>
            <div :class="{'EliasScharf_Hide': employee.Status !== '1'}" class="edit-employee-actions flex-direction-row">
                <div style="width:100%"></div>
                <DeactivateEmployee @deactivate-emplyoee="DeactivateEmployeeAccount"/>
                <DeleteEmployee @delete-emplyoee="DeleteEmployeeAccount" />
            </div>
            <div :class="{'EliasScharf_Hide': employee.Status !== '2'}" class="edit-employee-actions flex-direction-row">
                <div style="width:100%"></div>
                <ActivateAccount @activate-emplyoee="ActivateEmployeeAccount"/>
            </div>
        </div>

        <!--<p class="text-subtitle-2" style="font-size: 15px !important;padding-left:15px;padding-top:40px">Rechteverwaltung:</p>
        <v-table>
            <tbody>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
                <tr class="app-layout-table-item">
                    <td ><v-checkbox style="min-height:60px !important;height:60px !important" label="Rechte-Betreff"></v-checkbox> </td>
                </tr>
            </tbody>
        </v-table>-->
    </v-card>
</template>

<script>
import DeactivateEmployee from './DeactivateEmployee.vue'
import DeleteEmployee from './DeleteEmployee.vue'
import ActivateAccount from './ActivateAccount.vue'

export default {
    name: "EditEmployee-Component",
    components: {DeactivateEmployee, DeleteEmployee, ActivateAccount },
    props: {
        employee: {
            Type: Object,
            require: true
        }
    },
    data() {
        return {
            firstname: "",
            lastname: "",
            email: "",
            color: "",
        }
    },
    mounted() {
        if(window.innerWidth < 1100) {
            try {
                const style =  document.querySelector(".edit-employee-container").getAttribute("style")
                    document.querySelector(".edit-employee-container").setAttribute("style", `${style};top: 0px !important`)
                } catch (error) {
                    console.warn(error);
                }
        }
        
        this.firstname = this.employee.Firstname
        this.lastname = this.employee.Lastname
        this.email = this.employee.Email
        this.color = this.employee.Color
    },
    methods: {
        async SaveChanges() {
            const employees = await this.SendPost("/salonmanager/edit-employee", {id: this.employee.Id, color: this.color})
            this.$emit("update-employees", JSON.stringify(employees))
        },
        async ActivateEmployeeAccount(id) {
            id = this.employee.Id
            const employees = await this.SendPost("/salonmanager/activate-employee", {id: id})
            this.$emit("update-employees", JSON.stringify(employees))
        },
        async DeactivateEmployeeAccount(id) {
            id = this.employee.Id
            const employees = await this.SendPost("/salonmanager/deactivate-employee", {id: id})
            this.$emit("update-employees", JSON.stringify(employees))
        },
        async DeleteEmployeeAccount(id) {
            id = this.employee.Id
            const employees = await this.SendPost("/salonmanager/delete-employee", {id: id})
            this.$emit("update-employees",  JSON.stringify(employees))
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    alert(await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        },
        CloseEmployeePage() {
            this.$emit("close")
        }
    },
}
</script>

<style scoped>
.app-layout-table {
    background: #1976D2;
    color:white;
    z-index: 5 !important;
    overflow: hidden;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
}
@media(max-width: 700px) {
    .edit-employee-actions {
        flex-direction: column !important;
    }
    .edit-employee-actions > button {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
}
.deactiviated-notification {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color:rgba(255, 166, 0, 0.474);
    width:110px;
    text-align: center;
    font-weight: 500;
    border-radius: 20px;
    margin-left: 15px;
}
</style>