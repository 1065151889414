<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn class="ministration-edit-btn" v-bind="activatorProps" icon="mdi-pen"></v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Kundenprofil bearbeiten">
                <v-card-text style="padding-bottom:20px">
                    <div class="flex-direction-row" style="margin-top:5px">
                        <v-text-field v-model="firstname" label="*Vorname..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <div style="width:20px"></div>
                        <v-text-field v-model="lastname" label="*Nachname..." variant="outlined" class="add-register-textfield"></v-text-field>
                    </div>

                    <v-text-field v-model="email" type="email" label="E-Mail..." variant="outlined" class="add-register-textfield"></v-text-field>
                    <v-text-field v-model="phone" label="Telefon..." variant="outlined" class="add-register-textfield"></v-text-field>
                    <v-text-field v-model="birthday" type="date" label="Geburtsdatum..." variant="outlined" class="add-register-textfield"></v-text-field>

                    <v-btn style="width:100%;height:50px;" @click="addAdressDataDialog = true" variant="tonal" color="darkgrey">Adressdaten einsehen</v-btn>

                    <v-dialog max-width="500" v-model="addAdressDataDialog">
                        <v-card title="Adressdaten einsehen">
                            <v-card-text style="padding-bottom:5px">
                                <v-text-field style="margin-top:20px" v-model="city" label="Stadt..." variant="outlined" class="add-register-textfield"></v-text-field>
                                <v-text-field v-model="street" label="Straße..." variant="outlined" class="add-register-textfield"></v-text-field>
                                <div class="flex-direction-row" >
                                    <v-text-field v-model="houseNumber" label="Hausnummer..." variant="outlined" class="add-register-textfield"></v-text-field>
                                    <div style="width:20px"></div>
                                    <v-text-field v-model="postalCode" label="Postleizahl..." variant="outlined" class="add-register-textfield"></v-text-field>
                                </div>

                                <div class="add-register-action" style="margin-top: 30px !important;padding-bottom:20px">
                                    <div class="width-100"></div>
                                    <v-btn @click="this.addAdressDataDialog = false" >Speichern</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    
                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red;margin-top:20px">{{ error_message }}</p>

                    <div class="add-register-action" style="margin-top: 30px !important">
                        <v-btn @click="DeleteCustomer(isActive)">löschen</v-btn>
                        <div class="width-100"></div>
                        <v-btn @click="TryToCreate(isActive)">Speichern</v-btn>
                    </div>

                    <v-dialog max-width="500" v-model="askDialogBecauseOfAdress">
                        <v-card title="Adresse vervollständigen?">
                            <v-card-text style="padding-bottom:20px">
                                Um die Adresse des Kunden speichern zu können, muss diese vollstädndig ausgefüllt sein. Sie haben das Formular jedoch nicht vollständig ausgefüllt und möchten fortfahren. Wenn Sie die Aktion bestätigen, wird die Adresse NICHT gespeichert. Möchten Sie das wirklich? 

                                <div class="add-register-action" style="margin-top: 30px !important">
                                    <v-btn @click="this.askDialogBecauseOfAdress = false" >Abbrechen</v-btn>
                                    <div class="width-100"></div>
                                    <v-btn @click="AddRegister(isActive)">Bestätigen</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddCustomer-Component", 
    data() {
        return {
            askDialogBecauseOfAdress: false,
            addAdressDataDialog: false,
            registerName: "",

            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            birthday: "",

            city: "",
            street: "",
            houseNumber: "",
            postalCode: "",
            id: "",

            error_message: ""
        }
    },
    props: {
        customer: {
            Type: Object,
            required: true
        }
    },
    mounted() {
        this.firstname = this.customer.Firstname;
        this.lastname = this.customer.Lastname;
        this.email = this.customer.Email;
        this.phone = this.customer.Phone;
        this.birthday = this.customer.Birthday;
        this.city = this.customer.City;
        this.street = this.customer.Street;
        this.houseNumber = this.customer.HouseNumber;
        this.postalCode = this.customer.PostalCode;
        this.id = this.customer.Id;
    },
    methods: {
        TryToCreate(isActive) {
            let hasToShow = false;
            if(this.IsNotNull(this.city)) {
                if( !this.IsNotNull(this.street) || !this.IsNotNull(this.houseNumber) || !this.IsNotNull(this.postalCode)) {
                    hasToShow = true;
                }
            }
            if(this.IsNotNull(this.street)) {
                if( !this.IsNotNull(this.city) || !this.IsNotNull(this.houseNumber) || !this.IsNotNull(this.postalCode)) {
                    hasToShow = true;
                }
            }
            if(this.IsNotNull(this.houseNumber)) {
                if( !this.IsNotNull(this.street) || !this.IsNotNull(this.city) || !this.IsNotNull(this.postalCode)) {
                    hasToShow = true;
                }
            }
            if(this.IsNotNull(this.postalCode)) {
                if( !this.IsNotNull(this.street) || !this.IsNotNull(this.houseNumber) || !this.IsNotNull(this.city)) {
                    hasToShow = true;
                }
            }

            if(hasToShow) {
                this.askDialogBecauseOfAdress = true;
            } else {
                this.AddRegister(isActive)
            }
        },
        IsNotNull(data) {
            if(data !== null && data !== undefined && data.length !== 0) {
                return true;
            } else {
                return false;
            }
        },
        async AddRegister(isActive) {
            const status = await this.$validator.CustomerInfoAreValid(this.firstname, this.lastname, this.email, this.phone, this.birthday, this.city, this.street, this.houseNumber, this.postalCode);
            
            if(typeof status === 'string') {
                this.error_message = await this.$errorcodes.GetErrorMessage(status, "DE")
                return;
            }

            isActive.value = false
            this.error_message = "";
            
            if(this.birthday !== null && this.birthday !== undefined) {
                this.birthday = String(this.birthday.split('T')[0].toString())
            }

            this.$emit("update-customer", {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                phone: this.phone,
                birthday: this.birthday,

                city: this.city,
                street: this.street,
                houseNumber: this.houseNumber,
                postalCode: this.postalCode,
                id: this.id
            })
            
            this.firstname = "";
            this.lastname =  "";
            this.email =  "";
            this.phone = "";
            this.birthday =  "";
            this.city = "";
            this.street =  "";
            this.houseNumber =  "";
            this.postalCode =  "";
            this.id = "";

            this.askDialogBecauseOfAdress = false;
        },
        DeleteCustomer(isActive) {
            isActive.value = false
            this.error_message = "";
            
            this.$emit("delete-customer", {
                id:this.id
            })
        },
    }
}
</script>

<style scoped>
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:0px;
}
</style>