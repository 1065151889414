const calculator = {
    AddSecondsToDateTime(dateTime, secondsToAdd) {
        let [datePart, timePart] = dateTime.split(" ");
    
        let dateParts = datePart.split("-");
        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]) - 1; 
        let day = parseInt(dateParts[2]);
    
        let timeParts = timePart.split(":");
        let hours = parseInt(timeParts[0]);
        let minutes = parseInt(timeParts[1]);
        let seconds = parseInt(timeParts[2]);
    
        let originalDate = new Date(year, month, day, hours, minutes, seconds);
        let newDate = new Date(originalDate.getTime() + secondsToAdd * 1000);
    
        return `${(newDate.getFullYear())}-${(this.AddLeadingZero(newDate.getMonth() + 1))}-${(this.AddLeadingZero(newDate.getDate()))} ${(this.AddLeadingZero(newDate.getHours()))}:${(this.AddLeadingZero(newDate.getMinutes()))}:${(this.AddLeadingZero(newDate.getSeconds()))}`;
    },
    RemoveSecondsFromDateTime(datetimeString, secondsToRemove) {
        const dateTime = new Date(datetimeString);
        dateTime.setSeconds(dateTime.getSeconds() - secondsToRemove);

        return `${dateTime.getFullYear()}-${this.AddLeadingZero(dateTime.getMonth() + 1)}-${this.AddLeadingZero(dateTime.getDate())} ${this.AddLeadingZero(dateTime.getHours())}:${this.AddLeadingZero(dateTime.getMinutes())}:${this.AddLeadingZero(dateTime.getSeconds())}`;
    },
    DateTimeIsBetweenBothTimes(startDate, endDate, currentDate) {
        const startTime = new Date(startDate);
        const endTime = new Date(endDate);
        const currentTime = new Date(currentDate);

        return startTime <= currentTime && currentTime <= endTime;
    },
    AddLeadingZero: function (number) {
        return (number < 10 ? '0' : '') + number;
    },
    GetMinutesBetweenTime(time1, time2) {
        const [hours1, minutes1] = time1.split(':').map(Number);
        const [hours2, minutes2] = time2.split(':').map(Number);
    
        const min1 = (hours1 * 60) + minutes1
        const min2 = (hours2 * 60) + minutes2

        return (min2 - min1);
    },
}

export default calculator;
