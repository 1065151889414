<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn @click="LoadAllData" v-bind="activatorProps" icon="mdi-pen" style="height:30px;border-radius: 10px;font-size: 12px !important;top:15px;right:85px;position: absolute"></v-btn>
        </template>

        <template v-slot:default="{ isActive }" >
            <v-card title="Termin bearbeiten">
                <div style="margin-left:30px;margin-right:30px;margin-top:5px">
                    <div class="flex-direction-row">
                        <div style="width:100%"></div>
                        <p>{{step}}&nbsp;/&nbsp;<strong>3</strong></p>
                    </div>
                    <br>
                    <div :class="{'EliasScharf_Hide': step !== 1}">
                        <v-text-field v-model="Date" label="Datum..." type="date" variant="outlined" class="add-register-textfield"></v-text-field>
                        
                        <div class="flex-direction-row">
                            <div class="flex-direction-row" style="width:100%">
                                <v-select label="Start" v-model="startH" :items="hours" variant="outlined"></v-select>
                                <div style="width:5px"></div>
                                <v-select :items="minutes" v-model="startM" variant="outlined"></v-select>
                            </div>
                            <div style="min-width:20px;"></div>
                            <div class="flex-direction-row" style="width:100%">
                                <v-select label="Ende" v-model="endH" :items="hours" variant="outlined"></v-select>
                                <div style="width:5px"></div>
                                <v-select :items="minutes" v-model="endM" variant="outlined"></v-select>
                            </div>
                        </div>

                        <v-select v-model="Type" :items="['Einzelbesuch', 'Familienbesuch']" @update:modelValue="UpdateOther" :values="['1', '2']" variant="outlined"></v-select>

                        <div class="flex-direction-row">
                            <div style="width:100%"></div>
                            <v-btn @click="step++">Weiter</v-btn>
                        </div>
                        <br>
                    </div>

                    <!-- ALONEVISIT -->
                    <div :class="{'EliasScharf_Hide': step !== 2 || Type == 'Familienbesuch'}">
                        <div :class="{'EliasScharf_Hide': CustomerId !== ''}">
                            <SearchCustomer @select-customer="SelectedCustomer" v-if="loaded" :customers="eventCustomers" />
                        </div>

                        <div :class="{'EliasScharf_Hide': CustomerId == ''}">
                            <v-card style="margin-bottom: 10px;width: calc(100%);" :title="customer.Firstname + ' '+customer.Lastname" variant="tonal" v-if="CustomerId !== ''">
                                <p style="padding-left:15px;margin-top:-10px !important;margin-bottom:10px" v-if="customer.Email !== null">E-Mail: {{ customer.Email }}</p>
                                <p style="padding-left:15px;margin-top:-10px !important;margin-bottom:10px" v-if="customer.Phone !== null">Telefon: {{ customer.Phone }}</p>
                                <p style="padding-left:15px;margin-top:-10px !important;margin-bottom:10px" v-if="customer.City !== null">Stadt: {{ customer.City }}</p>
                            </v-card>
                            <v-btn icon="mdi-trash-can" color="red" style="height:40px;width:100%;border-radius: 10px !important;margin-bottom:20px !important" @click="CustomerId = '', customer = null"></v-btn>
                        </div>
                       
                        <div class="flex-direction-row" style="margin-top:20px;margin-bottom:30px" :class="{'EliasScharf_Hide': CustomerId !== ''}">
                            <hr style="width:100%">
                            <p style="padding-left: 10px;padding-right:10px;line-height:0px">ODER</p>
                            <hr style="width:100%">
                        </div>

                        <div class="flex-direction-row" :class="{'EliasScharf_Hide': CustomerId !== ''}">
                            <v-text-field v-model="Firstname" label="*Vorname..." variant="outlined" class="add-register-textfield"></v-text-field>
                            <div style="width:10px"></div>
                            <v-text-field v-model="Lastname" label="*Nachname..." variant="outlined" class="add-register-textfield"></v-text-field>
                        </div>
                        <v-text-field v-model="Email" :class="{'EliasScharf_Hide': CustomerId !== ''}" label="E-Mail..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <v-text-field v-model="Phone" :class="{'EliasScharf_Hide': CustomerId !== ''}" label="Telefon..." variant="outlined" class="add-register-textfield"></v-text-field>

                        <div class="flex-direction-row">
                            <v-btn @click="step--">Zurück</v-btn>
                            <div style="width:100%"></div>
                            <v-btn @click="step++">Weiter</v-btn>
                        </div>
                        <br>
                    </div>

                    <!-- FAMILIYVISIT -->
                    <div :class="{'EliasScharf_Hide': step !== 2 || Type == 'Einzelbesuch'}">
                        <div class="flex-direction-row">
                            <v-text-field v-model="FamilyName" label="*Familienname..." variant="outlined" class="add-register-textfield"></v-text-field>
                        </div>

                        <v-text-field v-model="FamilyMembers" label="*Anazhl..." type="number" variant="outlined" class="add-register-textfield"></v-text-field>
                        <v-text-field v-model="Email" label="E-Mail..." variant="outlined" class="add-register-textfield"></v-text-field>
                        <v-text-field v-model="Phone" label="Telefon..." variant="outlined" class="add-register-textfield"></v-text-field>

                        <div class="flex-direction-row">
                            <v-btn @click="step--">Zurück</v-btn>
                            <div style="width:100%"></div>
                            <v-btn @click="step++">Weiter</v-btn>
                        </div>
                        <br>
                    </div>

                    <!-- FAMILIYVISIT -->
                    <div :class="{'EliasScharf_Hide': step !== 3}">
                        <SearchMinistration @select-ministration="AddMinistration" v-if="loaded" :ministrations="allMinistrations" :registers="eventRegister" />

                        <div v-for="addedMinistration in addedMinistrations" :key="addedMinistration.id" class="flex-direction-row">
                            <v-card style="margin-bottom: 10px;height:40px;line-height:40px;width: calc(100% - 50px);" variant="tonal"><p style="padding-left: 10px;font-size:15px;">{{ addedMinistration.amount + 'x '+addedMinistration.name }}</p> </v-card>
                            <v-btn icon="mdi-trash-can" color="red" style="height:40px;width:50px;border-radius: 10px !important;margin-bottom:20px !important" @click="DeleteAddedMinistration(addedMinistration.id)"></v-btn>
                        </div>

                        <v-textarea  v-model="Description" label="Beschreiben..." variant="outlined"></v-textarea>

                        <div :class="{'EliasScharf_Hide': selectedEmployeeID !== '' && selectedEmployeeID !== null}">
                            <SearchEmployee v-if="loaded" :employees="this.employees" @select-employee="SelectEmployee"/>
                        </div>

                        <div :class="{'EliasScharf_Hide': selectedEmployeeID == ''}" v-if="selectedEmployeeID !== null && selectedEmployeeID !== ''">
                            <v-card style="margin-bottom: 10px;width: calc(100%);" :title="selectedEmployee.Firstname + ' '+selectedEmployee.Lastname" variant="tonal" v-if="selectedEmployeeID !== ''">
                            </v-card>
                            <v-btn icon="mdi-trash-can" color="red" style="height:40px;width:100%;border-radius: 10px !important;margin-bottom:20px !important" @click="DeleteEmplyoee"></v-btn>
                        </div>

                        <div class="flex-direction-row">
                            <v-btn @click="step--">Zurück</v-btn>
                            <div style="width:100%"></div>
                            <v-btn @click="AddEvent(isActive)" >speichern</v-btn>
                        </div>
                        <br>
                    </div>
                </div>
                
                <p  :class="{'EliasScharf_Hide': error_message == ''}" style="color:red;padding-left:30px;padding-bottom: 20px;">{{ error_message }}</p>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import SearchCustomer from './SearchCustomer.vue'
import SearchMinistration from './SearchMinistration.vue'
import SearchEmployee from './SearchEmployee.vue'

export default {
    name: "AddRegister-Component", 
    components: {
        SearchCustomer, SearchMinistration, SearchEmployee
    },
    data() {
        return {
            CustomerId: "",
            EmployeeId: "",
            Type: "Einzelbesuch",
            Firstname: "",
            Lastname: "",
            Email: "",
            Phone: "",
            Date: "", 
            StartTime: "", 
            EndTime: "",
            Description: "",
            FamilyName: "",
            FamilyMembers: "",
            Id: "",
            
            error_message: "",

            step: 1,

            hours: [],
            minutes: [],
            eventCustomers: [],
            eventMinistrations: [],
            eventRegister: [],

            selectedEmployee: {},
            selectedEmployeeID: "",

            addedMinistrations: [],

            customer: {},

            startH: "00",
            startM: "00",
            endH: "00",
            endM: "00",

            mobileView: false,
            loaded: false
        }
    },
    props: {
        allMinistrations: {
            Type: Array
        },
        view_event: {
            Type: Object
        },
        date: {
            Type: String
        },
        customers: {
            Type: Array
        },
        ministrations: {
            Type: Array
        },
        registers: {
            Type: Array
        },
        employees: {
            Type: Array
        },
    },
    mounted() {
        this.FillData()
        this.LoadAllData()
    },
    methods: {
        LoadAllData() {
            if(window.innerWidth < 800) {
                this.mobileView = true;
            }
            
            this.eventCustomers = this.customers;
            this.eventMinistrations = this.ministrations
            this.eventRegister = this.registers

            this.Date = String(this.view_event.event.Date).split('T')[0]

            this.CustomerId = this.view_event.event.CustomerId
            this.EmployeeId = this.view_event.event.EmployeeId

            if(this.view_event.event.Type == "0") {
                this.Type = "Einzelbesuch"
            } else {
                this.Type = "Familienbesuch"
            }
            
            this.Firstname = this.view_event.event.Firstname
            this.Lastname = this.view_event.event.Lastname

            this.Email = this.view_event.event.Email
            this.Phone = this.view_event.event.Phone
            this.Description = this.view_event.event.Description
            this.FamilyName = this.view_event.event.FamiliyName
            this.FamilyMembers = this.view_event.event.FamilyMembers
            this.Id = this.view_event.event.Id
            this.selectedEmployeeID = this.view_event.event.EmployeeId
            
            this.addedMinistrations = this.ministrations

            this.startH = this.view_event.event.StartTime.split(':')[0]
            this.startM = this.view_event.event.StartTime.split(':')[1]
            this.endH = this.view_event.event.EndTime.split(':')[0]
            this.endM = this.view_event.event.EndTime.split(':')[1]

            this.SelectEmployee(this.view_event.event.EmployeeId)

            if(this.view_event.event.CustomerId !== null && this.view_event.event.CustomerId !== undefined && this.view_event.event.CustomerId.length == 36) {
                this.customer = this.customers.find(e => e.Id == this.view_event.event.CustomerId)
            }

            this.loaded = true
        },
        UpdateOther() {
            if(this.Type == "Einzelbesuch") {
                this.FamilyMembers = "";
                this.FamilyName = "";
                this.CustomerId = "";
            } else {
                this.Firstname = ""
                this.Lastname = ""
                this.CustomerId = "";
            }
        },
        DeleteEmplyoee() {
            this.selectedEmployeeID = ""
            this.selectedEmployee = {}
        },
        SelectEmployee(id) {
            try {
                if(id !== null) {
                    this.selectedEmployeeID = id
                    this.selectedEmployee = this.employees.find(e => e.Id == id)
                }
            } catch(error) {
                console.info(error)
            }
        },
        AddMinistration(id) {
            const ministrationFound = this.allMinistrations.find(ele => ele.Id == id)
            if(ministrationFound == null ||ministrationFound == undefined) {
                return;
            }

            const e = this.addedMinistrations.find(e => e.id == id) 

            if(e == undefined || e == null) {
                this.addedMinistrations.push(
                    {
                        id: id,
                        amount: 1,
                        name: ministrationFound.Name
                    }
                )
            } else {
                e.amount++
            }
        },
        DeleteAddedMinistration(id) {
            const e = this.addedMinistrations.find(e => e.id == id) 
            e.amount--
            if(e.amount == 0) {
                const index = this.addedMinistrations.indexOf(e)
                this.addedMinistrations.splice(index, 1)
            }
        },
        SelectedCustomer(id) {
            setTimeout(() => {
                this.Firstname = "";
                this.Lastname = "";
                this.Email = "";
                this.Phone = "";
                try {
                    this.CustomerId = id
                    this.customer = this.customers.find(e => e.Id == id)
                } catch(error) {
                    this.CustomerId = ""
                    console.error(error)
                }
            }, 10)
        },
        FillData() {
            this.minutes.push("00")
            for (var i = 1; i <= 59; i++) {
                var numString = i.toString();
                
                if (numString.length < 2) {
                    numString = '0' + numString;
                }
                
                this.minutes.push(numString);
            }

            this.hours.push("00")
            for (var x = 1; x <= 24; x++) {
                var numString2 = x.toString();
                
                if (numString2.length < 2) {
                    numString2 = '0' + numString2;
                }
                
                this.hours.push(numString2);
            }
        },
        OpenModel() {
            this.eventCustomers = this.customers;
            this.eventMinistrations = this.ministrations
            this.eventRegister = this.registers
            
            this.Date = this.$datetime.GetUSDateFromEU(this.date)
        },
        async AddEvent() {
            this.EmployeeId = this.selectedEmployeeID
            this.StartTime = `${this.startH}:${this.startM}`
            this.EndTime = `${this.endH}:${this.endM}`

            if(this.EmployeeId == "") {
                this.EmployeeId = null
            }
            if(this.FamilyMembers == "") {
                this.FamilyMembers = null;
            }
            if(this.FamilyName == "") {
                this.FamilyName = null;
            }

            const status = this.$validator.IsEventDataValid(this.FamilyMembers, this.FamilyName,
                this.CustomerId, this.EmployeeId, this.Type, this.Firstname, this.Lastname,
                this.Email, this.Phone, this.Date, this.StartTime, this.EndTime,
                this.Description
            ) 

            if(typeof status === "string") {
                this.error_message = await this.$errorcodes.GetErrorMessage(status, "DE")
                return 
            }
            const data = {id:this.Id, familyName: this.FamilyName, familyMembers: this.FamilyMembers,ministrations: this.addedMinistrations, CustomerId: this.CustomerId, EmployeeId: this.EmployeeId, Type:this.Type,Firstname: this.Firstname, Lastname:this.Lastname,Email:this.Email, Phone:this.Phone, Date:this.Date, StartTime:this.StartTime, EndTime:this.EndTime, Description: this.Description}
            this.$emit("update-event", data)
        },
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
       height:50px !important;
       width: 50px !important; 
       max-height:50px !important;
       max-width: 50px !important; 
       min-height:50px !important;
       min-width: 50px !important; 
       padding-right: 4px !important;
       position: fixed;
       bottom: 30px;
       margin: 0px !important;
       right: 30px;
       z-index: 5;
       border-radius: 50%;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left: auto;
    margin-right: 80px;
    margin-top: 12px;
}
.add-register-textfield {
    margin-top:0px;
}
</style>