<template>
  <div class="authentication-container">
    <Sidebar />

    <div class="authentication-form" :class="{'EliasScharf_Hide':this.$route.path.split('/')[3] == 'set-password'}">
      <h5 class="text-h5 typeface-figtree">Konto aktivieren</h5>
      <p class="text-body-1">Geben Sie den Sechsstelligen Aktivierungscode an, den wir Ihnen gesendet haben.</p>
      <v-otp-input v-model="verifyCode" style="min-width:300px;width:300px;margin-left:-10px;margin-top:-10px"></v-otp-input>
      <div class="authentication-actions" style="margin-top:30px;">
        <div class="width-100"></div>
        <v-btn color="primary" rounded class="authentication-button" style="width:100%" @click="this.$router.push(`${this.$route.path}/set-password`)">&nbsp;Weiter&nbsp;</v-btn>
      </div>

      <p class="text-body-1">Sollten Sie bei der Kontoaktivierung Hilfe benötigen, finden Sie diese auf unserer Elias-Scharf <a :href="this.$store.state.Config.Url.Help" target="_blank" class="authentication-link">Hilfe-Seite</a>.</p>
    </div>

    <div class="authentication-form" :class="{'EliasScharf_Hide': this.$route.path.split('/')[3] !== 'set-password'}">
      <h5 class="text-h5 typeface-figtree">Kennwort vergeben</h5>
      <p class="text-body-1">Legen Sie ein Passwort fest, um sich später mit Ihrer E-Mail-Adresse und dem Passwort anzumelden.</p>
      <v-text-field label="Kennwort vergeben..." type="password" v-model="password" variant="outlined" @keyup="changedPassword" @change="changedPassword"></v-text-field>
      <v-checkbox label="Mindestens 8 Zeichen" v-model="correctPassword" style="margin-left:-10px;margin-top:-20px;margin-bottom:-10px" disabled></v-checkbox>
      <v-checkbox v-model="code" label="Ich habe die AGB's, die Datenschutzverordnung sowie die Geschäftsbedingungen gelesen und bestätige diese." style="margin-left:-12px;margin-top:10px" class="authentication-checkbox" color="primary"></v-checkbox>
      <div class="authentication-actions">
        <v-btn rounded class="authentication-button" @click="this.$router.push(this.baseUrl)">&nbsp;Zurück&nbsp;</v-btn>
        <div class="width-100"></div>
        <v-btn color="primary" rounded class="authentication-button" @click="ActiveAccount('/verify-account/')">&nbsp;Bestätigen&nbsp;</v-btn>
      </div>

      <p class="text-body-1">Sollten Sie bei der Kontoaktivierung Hilfe benötigen, finden Sie diese auf unserer Elias-Scharf <a :href="this.$store.state.Config.Url.Help" target="_blank" class="authentication-link">Hilfe-Seite</a>.</p>
    </div>
  </div>
</template>

<script>
import Sidebar from '../../../components/Authentication/Sidebar.vue'

export default {
  name: 'Authentication-View',
  components: {
    Sidebar,
  },
  data() {
    return {
      baseUrl: "",
      verifyCode: "",
      password: "",
      accept_all: false,
      correctPassword: false,
      alert: {
        show: false,
        text: "",
      }
    }
  },
  mounted() {
    this.$store.state.Config.Default.Pageloading = false
  },
  methods: {
    changedPassword() {
      if(this.password.length >= 8) {
        this.correctPassword = true;
      } else {
        this.correctPassword = false;
      }
    },
    async ActiveAccount(route) {
      if(!this.$validator.HasLength(this.verifyCode, 6)) {
        this.alert.text = await this.$errorcodes.GetErrorMessage("000043", "DE") 
        this.alert.show = true
        setTimeout(() => {
          this.alert.show = false
          this.alert.text = ""
        }, 2500)
        return
      }
      if(!this.$validator.HasMinLength(this.password, 8)) {
        this.alert.text = await this.$errorcodes.GetErrorMessage("000044", "DE") 
        this.alert.show = true
        setTimeout(() => {
          this.alert.show = false
          this.alert.text = ""
        }, 2500)
        return
      }

      try {
        this.$store.state.Config.Default.Pageloading = true
        const response = await fetch(`${this.$store.state.Config.Url.Server}${route}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify( {sessionId: this.$route.path.split("/")[2], password: this.password, verifyCode: this.verifyCode} ),
          });

          if (response.ok) {
            this.$store.state.Config.Default.Pageloading = false
            this.$router.push("/")
          } else {
            this.$store.state.Config.Default.Pageloading = false
            this.$emit("error", await response.text())
          }
      } catch (error) {
        console.error('Error: ', error.message);
      }
    }
  },
}
</script>

<style scoped>
.authentication-alert {
  position:fixed;
  left:50%;
  top:50px;
  width:400px;
  margin-left:-200px
}
.authentication-button {
  text-transform: none !important;
  font-size: 16px;
  letter-spacing: 0.45px;
  border-radius: 5px;
  height:40px !important;
}
.text-h5 {
  font-size: 34px !important;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 5px;
}
.authentication-container,
.authentication-actions {
  display: flex;
  flex-direction: row;
}
.authentication-form {
  padding: 80px 0px 0px 80px;
  width: 500px;
}
.text-body-1{
  letter-spacing: 0px !important;
  margin-bottom: 30px;
  font-size: 18px !important;
  line-height: 26px;
  color: rgb(75, 75, 75);
  font-weight: 400;
}
.authentication-actions {
  margin-top: -0px;
  margin-bottom: 45px;
}
.authentication-textbox::placeholder {
  color: black !important;
}
.authentication-link {
  color: #1E88E5;
  text-decoration: none;
}
.authentication-link:hover {
  color: #1976D2;
  text-decoration: underline;
}
.authentication-checkbox{
  margin-left: -7px;
  margin-top: -10px;
}

@media(max-width: 680px) {
  .authentication-container{
    flex-direction: column;
  }
  .authentication-form{
    margin: 40px 31px 0px 31px;
    padding: 0px;
    width:calc(100% - 62px);
  }
}

</style>
