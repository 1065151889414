<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="add-register-open-btn" prepend-icon="mdi-plus">Register</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Register erstellen">
                <v-card-text style="padding-bottom:20px">
                    Geben Sie einen Namen für das Register ein und bestätigen Sie den Vorgang.

                    <v-text-field v-model="registerName" label="Registername..." variant="outlined" class="add-register-textfield"></v-text-field>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red">{{ error_message }}</p>

                    <div class="add-register-action">
                        <div class="width-100"></div>
                        <v-btn @click="AddRegister(isActive)">Erstellen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            registerName: "",
            error_message: ""
        }
    },
    methods: {
        async AddRegister(isActive) {
            if(!this.$validator.IsBetweenLength(this.registerName, 1, 255)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000010", "DE")
                return;
            }

            if(!this.$validator.TextOnlyCharctersAndSpace(this.registerName)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000011", "DE")
                return;
            }

            isActive.value = false
            this.error_message = "";
            
            this.$emit("add-register", this.registerName)
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>