import { createStore } from 'vuex';

export default createStore({
  state: {
    Config: {
      Default: {
        Pageloading: false,
      },
      Cookies: {
        Authentication: "eliasscharf",
        Application: {
          Salonmanager: {
            Registers: "salonmanager_registers",
            Ministrations: "salonmanager_ministrations",
            Customers: "salonmanager_customers",
            Employees: "salonmanager_employees",
            EmployeesToView: "salonmanager_employee_view",
          }
        }
      },
      Authentication: {
        Data: {
          ExpiryDate: "",
          Token: "",
          SecondsToExpire: 0,
          Admin: false,
        },
        VerifyFailedURL: "/",
      },
      Application: { 
        General: {
          Code: "",
        },
        Salonmanager: {
          PostURL: "/salonmanager/",
          StartURL: "/salonmanager/calendar",
        }
      },
      Resources: {
        Logo: "https://cdn.eliasscharf.de/img/EliasScharf_Logo_Background.png",
        Logo_Alt: "Elias Scharf Logo",

        LogoTransparent: "https://cdn.eliasscharf.de/img/EliasScharf_Logo_Transparent.png",
        LogoTransparent_Alt: "Elias Scharf Logo Transparent",
      },
      Url: {
        Help: "https://hilfe.eliasscharf.de/",
        Cdn: "https://cdn.eliasscharf.de/",
        Live: "https://live.eliasscharf.de/",

        // Development http://localhost:3000
        // Production https://live.eliasscharf.de
        Server: "https://live.eliasscharf.de",
      },
    }
  },
});
