<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div>
                <div :class="{'EliasScharf_Hide': ministrationList.length !== 0}">
                    <p style="font-size: 22px;text-align: center; margin-top:30px">Es wurde noch kein Register erstellt</p>
                    <div class="flex-direction-row" style="margin-top:15px">
                        <div class="width-100"></div>
                        <AddRegister v-if="ministrationLoaded" @add-register="AddRegister" />
                        <div class="width-100"></div>
                    </div>
                </div>

                <div class="app-layout-header-controler" :class="{'EliasScharf_Hide': ministrationList.length == 0}">
                    <v-select label="Register" density="compact" @update:modelValue="UpdateSelectedRegister" :items="ministrationList" item-title="name" item-value="id" v-model="ministrationListId" variant="outlined" class="ministration-select"></v-select>
                    <EditRegister v-if="ministrationLoaded" @delete-register="DeleteRegister" @edit-register="UpdateRegister" :registerId="ministrationListId" :registers="ministrationList" />
                    <div style="width:100%"></div>
                    <div class="app-layout-desktop-view flex-direction-row">
                        <AddRegister v-if="ministrationLoaded" @add-register="AddRegister" />
                        <AddMinistration v-if="ministrationLoaded" @add-ministration="AddMinistration"/>
                    </div>

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn class="ministration-edit-btn ministration-edit-btn-mobile" v-bind="props" icon="mdi-plus"></v-btn>
                        </template>
                        <v-list class="ministration-menu-btn-mobile">
                            <v-list-item>
                                <v-list-item-title class="menu-list-btn"><AddRegister v-if="ministrationLoaded" @add-register="AddRegister" /></v-list-item-title>
                                <v-list-item-title class="menu-list-btn"><AddMinistration v-if="ministrationLoaded" @add-ministration="AddMinistration"/></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </div>
                
                <div class="table-holder" :class="{'EliasScharf_Hide': ministrationList.length == 0}">
                    <v-table class="table-holder-container">
                        <thead>
                            <tr class="app-layout-table">
                                <th class="text-left">Name</th>
                                <th class="text-left">Preis</th>
                                <th class="text-left">Dauer</th>
                                <th class="text-left">Aktion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="app-layout-table-item" v-for="ministration in ministrationItems" :key="ministration.id" :class="{'EliasScharf_Hide': ministrationListId !== ministration.RegisterId}">
                                <td>{{ ministration.Name }}</td>
                                <td>{{ String(ministration.Price) }} EUR </td>
                                <td>{{ ministration.Duration.split(":")[0] + ':' + ministration.Duration.split(":")[1]}} Stunde(n)</td>
                                <td style="width:100px"> <EditMinistration v-if="ministrationLoaded" :ministration="ministration" @edit-ministration="UpdateMinistration" @delete-ministration="DeleteMinistration" /> </td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>

                <p class="ministration-notify-no-ministrations" :class="{'EliasScharf_Hide': !CheckIfMinistrationsAreExising() || ministrationList.length == 0}">Keine Dienstleistungen für dieses Register gefunden.</p>
            </div>
        </div>
    </v-layout>
</template>

<script>
import CryptoJS from 'crypto-js';

import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

import AddRegister from '../../../components/Applications/Salonmanager/AddRegister.vue'
import AddMinistration from '../../../components/Applications/Salonmanager/AddMinistration.vue'
import EditRegister from '../../../components/Applications/Salonmanager/EditRegister.vue'
import EditMinistration from '../../../components/Applications/Salonmanager/EditMinistration.vue'

export default {
    name: "Salonmanager_Ministrations-View",
    components: { Header, Navigation, AddRegister, AddMinistration, EditRegister, EditMinistration },
    data() {
        return {
            ministrationListId: null,
            ministrationList: [],
            ministrationItems: [],

            ministrationLoaded: false
        }
    },
    async mounted() {
        await this.LoadRegistersFromCookies()
        await this.LoadMinistrationsFromCookies();
    },
    methods: {
        CheckIfMinistrationsAreExising() {
            if(this.ministrationItems.find(e => e.RegisterId == this.ministrationListId) == undefined) {
                return true;
            } else {
                return false;
            }
        },
        UpdateSelectedRegister() {
            this.$store.state.Config.Default.Pageloading = true
            this.ministrationLoaded = false;

            setTimeout(() => {
                this.ministrationLoaded = true;
                this.$store.state.Config.Default.Pageloading = false
            }, 15)
        },

        async LoadRegistersFromCookies() {
            try {
                const registers = this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.Registers}`)

                if(registers == null || registers == undefined || registers.legnth == 0) {
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Registers}`, null)
                } 

                let result = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'request-registers', { hash: CryptoJS.SHA256(JSON.stringify(registers)).toString() })
                
                if(result == true) {
                    this.FillRegisterList(registers)
                } else {
                    this.FillRegisterList(result)
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Registers}`, JSON.stringify(result))
                } 
            } 
            catch(error) {
                console.log(error)
            }
            
            this.ministrationLoaded = true;
        },
        async LoadMinistrationsFromCookies() {
            try {
                const ministrations = this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.Ministrations}`)

                if(ministrations == null || ministrations == undefined || ministrations.legnth == 0) {
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Ministrations}`, null)
                }
                
                let result = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'request-ministrations', { hash: CryptoJS.SHA256(JSON.stringify(ministrations)).toString() })
                
                if(result == true) {
                    this.FillMinistrationList(ministrations)
                } else {
                    this.FillMinistrationList(result)
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Ministrations}`, JSON.stringify(result))
                } 
            } 
            catch(error) {
                console.log(error)
            }
            
            this.ministrationLoaded = true;
        },

        FillMinistrationList(ministrations) {
            if(typeof ministrations === "string") {
                return;
            }

            this.ministrationItems = []

            ministrations.forEach(ministration => {
                this.ministrationItems.push(ministration)
            })

            this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Ministrations}`, JSON.stringify(ministrations))
        },
        FillRegisterList(registers) {
            if(typeof registers === "string") {
                return;
            }
            this.ministrationList = []

            registers.forEach(register => {
                this.ministrationList.push( { name: register.Name, id: register.Id } )
            })

            if(this.ministrationList.length !== 0) {
                this.ministrationListId = this.ministrationList[0].id;
            }

            this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Registers}`, JSON.stringify(registers))
        },

        async DeleteRegister() {
            const registers = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'delete-register', {registerId: this.ministrationListId})
            this.LoadMinistrationsFromCookies()
            this.FillRegisterList(registers)

            this.ministrationLoaded = true
        },
        async DeleteMinistration(ministrationId) {
            const ministrations = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'delete-ministration', ministrationId)
            this.FillMinistrationList(ministrations);
        },

        async UpdateMinistration(ministrationInfo) {
            const ministrations = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'edit-ministration', ministrationInfo)
            this.FillMinistrationList(ministrations);
        },
        async UpdateRegister(registerName) {
            const registers = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'edit-register', { registerName: registerName, registerId: this.ministrationListId})
            this.FillRegisterList(registers)
        },

        async AddRegister(registerName) {
            const registers = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'add-register', { registerName: registerName})
            this.FillRegisterList(registers)
        },
        async AddMinistration(ministrationInfo) {
            const ministrations = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL + 'add-ministration', { 
                ministrationName: ministrationInfo.ministrationName, 
                ministrationPrice: ministrationInfo.ministrationPrice, 
                ministrationDuration: ministrationInfo.ministrationDuration, 
                ministrationListId: this.ministrationListId
            })
            this.FillMinistrationList(ministrations);
        },

        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    this.$emit("error", await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    }
}
</script>

<style scoped>
.ministration-edit-btn-mobile {
    display: none !important;
}
.ministration-menu-btn-mobile {
    max-width:250px;
    min-width:250px;
    height:auto !important;
    left:calc(100% - 260px) !important;
}
.menu-list-btn > button {
    width: 100% !important;
    min-width: 100% !important;
    background:grey !important;
}
.ministration-notify-no-ministrations {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.app-layout-header-controler {
    margin-bottom: -5px;
    padding-right:15px;
    padding-left: 15px;
    display:flex;
    flex-direction: row;
    padding-top:20px;
    width: calc(100%px);
}
.ministration-btn {
    height:40px;
    margin-left:15px
}
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.ministration-select {
    width:260px;
    max-width: 260px;
    min-width: 260px
}
.app-layout-table {
    background: #1976D2;
    color:white;
    overflow: hidden;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
@media(max-width: 650px) {
    .table-holder {
        width: calc(100%);
        overflow:scroll !important
    }
    .table-holder-container {
        min-width: 650px;
        overflow:scroll !important;
    }
}
@media(max-width: 800px) {
    .app-layout-desktop-view {
        display: none !important;
    }
    .ministration-edit-btn-mobile{
        display: block !important;
    }
}
@media(max-width: 400px) {
    .ministration-select {
        width: 200px !important;
        min-width: 200px !important;
        max-width: 200px !important;
    }
}
</style>
