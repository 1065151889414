<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" style="width: 100%;margin-bottom: 30px;" @click="LoadData()">Dienstleistung hinzufügen</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Dienstleistung suchen">
                <v-card-text style="padding-bottom:20px">
                    <v-text-field v-model="searchInput" @keyup="Search()" style="margin-top:-5px !important" label="Nach Dienstleistung suchen..." variant="outlined" class="add-register-textfield"></v-text-field>
                    
                    <div style="max-height:400px;overflow-y: scroll;">
                        <v-card @click="Select(isActive, result.Id)" style="margin-bottom: 10px;" v-for="result in results" :key="result.id" :title="result.Name" variant="tonal">
                            <p style="padding-left:15px;margin-top:-10px !important;margin-bottom:10px" v-if="result.Price !== null">Preis: {{ result.Price }}</p>
                            <p style="padding-left:15px;margin-top:-10px !important;margin-bottom:10px" v-if="result.Duration !== null">Dauer: {{ result.Duration }}</p>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            customerId: "",
            searchInput: "",
            results: [],
            eventMinistrations: [],
            eventRegisters: [],
            eventRegister: {},
            loaded: false,
            selectedRegister: "",
        }
    },
    props: {
        ministrations: {
            Type: Array, 
        },
        registers: {
            Type: Array, 
        },
    },
    async mounted() {
        await this.LoadData();
        this.results = this.ministrations
    },
    methods: {
        selectRegsiter(id) {
            this.selectedRegister = id
        },  
        async LoadData() {
            this.eventMinistrations = await this.ministrations;
            this.eventRegisters = await this.registers
            
            this.loaded = true;
        },
        Select(isActive, id) {
            isActive.value = false;
            this.$emit("select-ministration", id)
        },
        async Search() {
            await this.LoadData()
            this.results = []

            this.ministrations.forEach(ministration => {
                const name = (`${ministration.Name}`).toLowerCase();

                if(name.includes((this.searchInput).toLowerCase())) {
                    this.results.push(ministration)
                }
            })
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>