import { createApp } from 'vue'
import App from './App.vue'

// Import utils
import Calculator from './assets/utils/calculator.js'
import DateTime from './assets/utils/datetime.js'
import Validator from './assets/utils/validator.js'
import ErrorCodes from './assets/utils/errorcodes.js'

// Import vue-router
import VueRouter from './vue-router/vue-router.js'

// Import vue-store
import VueStore from './vue-store/vue-store.js'

// Import vue-cookies
import VueCookies from 'vue-cookies'

// Import CryptoJS
import CryptoJS from 'crypto-js';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App);

// Set utils
app.config.globalProperties.$calculator = Calculator;
app.config.globalProperties.$datetime = DateTime;
app.config.globalProperties.$validator = Validator;
app.config.globalProperties.$errorcodes = ErrorCodes;

app.use(vuetify)
app.use(VueRouter)
app.use(VueCookies)
app.use(VueStore)
app.use(CryptoJS)

app.mount('#app')
