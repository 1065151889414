<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" style="width: 100%;margin-bottom: 20px;">mitarbeiter zuweisen</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Kundenprofil suchen">
                <v-card-text style="padding-bottom:20px">
                    <v-text-field v-model="searchInput" @keyup="Search()" label="Nach Kundenprofil suchen..." variant="outlined" class="add-register-textfield"></v-text-field>
                    
                    <div style="max-height:400px;overflow-y: scroll;">
                        <v-card @click="Select(isActive, result.Id)" style="margin-bottom: 10px;" v-for="result in results" :key="result.id" :title="result.Firstname + ' '+result.Lastname" variant="tonal">
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            customerId: "",
            searchInput: "",
            results: []
        }
    },
    props: {
        employees: {
            Type: Array, 
        }
    },
    mounted() {
        this.results =this.employees;
    },
    methods: {
        Select(isActive, id) {
            isActive.value = false;
            this.$emit("select-employee", id)
        },
        Search() {
            var customers = this.employees;
            this.results = []

            customers.forEach(customer => {
                const name = (`${customer.Firstname} ${customer.Lastname}`).toLowerCase();

                if(name.includes((this.searchInput).toLowerCase())) {
                    this.results.push(customer)
                }
            })
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
    margin-left:15px
}
.add-register-textfield {
    margin-top:20px;
}
</style>