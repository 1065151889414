<template>
    <div>
        <v-dialog max-width="500" v-model="viewEvent">
            <v-card title="Termin einsehen">
                <v-card-text style="padding-bottom:5px">
                    <v-btn @click="Delete" icon="mdi-trash-can" style="height:30px;border-radius: 10px;font-size: 12px !important;top:15px;right:25px;position: absolute"></v-btn>
                    <EditEvent @update-event="Update" @open="OpenEditEvent" :allMinistrations="allMinistrations" v-if="editEvent && this.view_event.event.Status !== '2'" :view_event="view_event" :ministrations="ministrations" :customers="customers" :employees="employees" />

                    <div class="flex-direction-row view-event-navigation" style="width:100%;background:rgb(0,0,0,.1);border-radius: 10px;;">
                        <div :class="{'view-event-navigation-active': page == 1}" @click="page = 1">Allgemein</div>
                        <div :class="{'view-event-navigation-active': page == 2}" @click="page = 2">Dienstleistungen</div>
                    </div>
                    <div :class="{'EliasScharf_Hide': page !== 1}">
                        <p><strong>Datum:</strong> {{ this.$datetime.GetEUDateFromUS(String(view_event.event.Date).split('T')[0]) }} </p>
                        <p><strong>Uhrzeit:</strong> {{ view_event.event.StartTime.split(':')[0] + ':'+view_event.event.StartTime.split(':')[1] }} - {{ view_event.event.EndTime.split(':')[0] + ':'+view_event.event.EndTime.split(':')[1] }} Uhr</p>
                        <p v-if="this.GetEmployeeName(view_event.event.EmployeeId) !== null"><strong>Mitarbeiter:</strong> {{ this.GetEmployeeName(view_event.event.EmployeeId) }} </p>
                        <br>

                        <p v-if="view_event.event.Firstname !== '' && view_event.event.Firstname !== null && view_event.event.Firstname !== undefined"><strong>Vor-/Nachname:</strong> {{ view_event.event.Firstname }} {{ view_event.event.Lastname }}</p>
                        <p v-if="view_event.event.FamiliyName !== null && view_event.event.FamiliyName !== ''"><strong>Familienname:</strong> {{ view_event.event.FamiliyName }}</p>
                        <p v-if="selectedCustomer !== undefined"><strong>Kundenname:</strong> 
                            {{ selectedCustomer.Firstname }} {{ selectedCustomer.Lastname }}
                            <v-btn @click="viewCustomer = true" prepend-icon="mdi-account-circle" style="height:30px;border-radius: 10px;font-size: 12px !important;margin-left:10px">ANSEHEN</v-btn>
                        </p>

                        <p v-if="view_event.event.Type == '0'"><strong>Typ:</strong> Einzelbesuch </p> 
                        <p v-if="view_event.event.Type == '1'"> <strong>Typ:</strong> Familienbesuch </p> 
                        <p v-if="view_event.event.Type == '1'"> <strong>Besucher:</strong> {{ view_event.event.FamilyMembers }} </p> <br>

                        <p v-if="view_event.event.Email !== '' && selectedCustomer == undefined && view_event.event.Email !== null"><strong>E-Mail:</strong> {{ view_event.event.Email }} </p>
                        <p v-if="view_event.event.Phone !== '' && selectedCustomer == undefined && view_event.event.Phone !== null"><strong>Telefon:</strong> {{ view_event.event.Phone }} </p>

                        <br v-if="(view_event.event.Email !== '' && selectedCustomer == undefined) || (view_event.event.Phone !== '' && selectedCustomer == undefined)">
                        <p v-if="view_event.event.Description !== ''"><strong>Beschreibung:</strong> {{ view_event.event.Description }}</p>
                    </div>

                    <div :class="{'EliasScharf_Hide': page !== 2}">
                        <p><strong>Dienstleistungen:</strong> <span v-if="ministrations.length == 0">-</span> </p>
                        <div v-for="addedMinistration in ministrations" :key="addedMinistration.id" class="flex-direction-row">
                            <v-card style="margin-bottom:5px;width: calc(100%);" variant="tonal">
                                <strong>
                                    <p style="padding-left: 10px;font-size:15px;padding-top:5px">{{ addedMinistration.amount + 'x '+addedMinistration.name }}</p>
                                </strong>

                                <p style="padding-left:10px;" v-if="addedMinistration.Price !== null">Preis: {{ addedMinistration.Price }}</p>
                                <p style="padding-left:10px;margin-bottom:10px" v-if="addedMinistration.Duration !== null">Dauer: {{ addedMinistration.Duration }}</p>
                            </v-card>
                        </div>
                    </div>

                    <div class="add-register-action flex-direction-row" style="margin-top: 30px !important;padding-bottom:20px">
                        <div class="width-100" :class="{'EliasScharf_Hide': this.view_event.event.Status !== '2'}"></div>
                        <v-btn @click="this.viewEvent = false" >schlissen</v-btn>
                        <div class="width-100" :class="{'EliasScharf_Hide': this.view_event.event.Status == '2'}"></div>
                        <v-btn @click="SetEventAsDone" :class="{'EliasScharf_Hide': this.view_event.event.Status == '2'}">bestätigen</v-btn>
                    </div>
                </v-card-text>

                <v-dialog max-width="500" v-model="viewCustomer" v-if="selectedCustomer !== undefined">
                    <v-card title="Kundenprofil einsehen">
                        <v-card-text style="padding-bottom:5px">
                            <p> <strong>Vor-/Nachname: </strong> {{ selectedCustomer.Firstname }} {{ selectedCustomer.Lastname }}</p>
                            <p v-if="selectedCustomer.Email !== null"> <strong>E-Mail: </strong> {{ selectedCustomer.Email }}</p>
                            <p v-if="selectedCustomer.Phone !== null"> <strong>Telefon: </strong> {{ selectedCustomer.Phone }}</p>

                            <br v-if="selectedCustomer.Street !== null">

                            <div v-if="selectedCustomer.Street !== null">
                                <p> <strong>Stadt: </strong> {{ selectedCustomer.City }}</p>
                                <p> <strong>Straße: </strong> {{ selectedCustomer.Street }}</p>
                                <p> <strong>Hausnummer: </strong> {{ selectedCustomer.HouseNumber }}</p>
                                <p> <strong>Postleizahl: </strong> {{ selectedCustomer.PostalCode }}</p>
                            </div>

                            <div class="add-register-action" style="margin-top: 30px !important;padding-bottom:20px">
                                <div class="width-100"></div>
                                <v-btn @click="this.viewCustomer = false" >OK</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import EditEvent from './EditEvent.vue'

export default {
    components: { EditEvent},
    name: "ViewEvent-Component",
    data() {
        return {
            page: 1,

            editEvent: false,
            viewCustomer: false,
            viewEvent: false,

            view_event: [],
            ministrations: [],

            selectedCustomer: undefined,
        }
    },
    props: {
        events: {
            Type: Array
        },
        customers: {
            Type: Array,
        },
        employees: {
            Type: Array,
        },
        assigned_ministrations: {
            Type: Array,
        },
        allMinistrations: {
            Type: Array
        }
    },
    mounted() {
        this.editEvent = true;
    },
    methods: {
        SetEventAsDone() {
            this.viewEvent = false
            this.$emit("accept-event", this.view_event.event.Id)
        },
        async Update(id, familyName, familyMembers,ministrations,CustomerId, EmployeeId, Type, Firstname, Lastname, Email, Phone, Date, StartTime, EndTime, Description) {
            this.$emit("update", {id: id, familyName: familyName, familyMembers: familyMembers,ministrations: ministrations,CustomerId: CustomerId, EmployeeId:EmployeeId, Type:Type, Firstname:Firstname, Lastname:Lastname, Email: Email, Phone: Phone, Date:Date, StartTime:StartTime, EndTime: EndTime, Description: Description})
            this.viewEvent = false;
        },
        Delete() {
            this.$emit("delete", this.view_event.event.Id)
            this.viewEvent = false;
        },
        GetEmployeeName(id) {
            try {
                const employee = this.employees.find(e => e.Id == id)
      
                if(employee !== undefined && employee !== null) {
                    return `${employee.Firstname} ${employee.Lastname}`
                } else {
                    return null
                }
            } catch {
                return null
            }
        },
        GetCustomerInfoById(id) {
            try {
                const customer = this.customers.find(e => e.Id == id)
      
                if(customer !== undefined && customer !== null) {
                    this.selectedCustomer = customer;
                } else {
                    return `Nutzer existiert nicht mehr.`
                }
            } catch {
                return `Nutzer existiert nicht mehr.`
            }
        },
        Open(id) {
            this.editEvent = false
            this.page = 1
            this.ministrations = []
            this.selectedCustomer = undefined;

            const event = this.events.find(e => e.event.Id == id)

            if(event !== null && event !== undefined) {
                this.view_event = event;

                this.GetCustomerInfoById(this.view_event.event.CustomerId)

                for(var i = 0; i < this.assigned_ministrations.length; i++) {
                    if(this.assigned_ministrations[i].EventId == id) {
                        let ele = this.ministrations.find(e => e.id == this.assigned_ministrations[i].Id)

                        if(ele == undefined || ele == null) {
                            this.ministrations.push(
                                {
                                    amount:  this.assigned_ministrations[i].Amount,
                                    name: this.assigned_ministrations[i].Name,
                                    id: this.assigned_ministrations[i].Id,
                                    Price: this.GetPriceByItem(this.assigned_ministrations[i].MinistrationId),
                                    Duration: this.GetDurationByItem(this.assigned_ministrations[i].MinistrationId),
                                }
                            )
                        } else {
                            this.ministrations[this.ministrations.indexOf(ele)].amount = String(Number(ele.amount)+1)
                        }
                    }
                }
            }

            this.viewEvent = true
            setTimeout(() => {
                this.editEvent = true
            }, 10)
        },
        GetPriceByItem(id) {
            const item = this.allMinistrations.find(e => e.Id == id)
            return item.Price + " EUR"
        },
        GetDurationByItem(id) {
            const item = this.allMinistrations.find(e => e.Id == id)
            return `${item.Duration.split(':')[0]}:${item.Duration.split(':')[1]} Stunde(n)`
        },
    },
}
</script>

<style scoped>
.view-event-navigation{
    height:30px;
    margin-bottom: 10px;
    overflow: hidden;
}
.view-event-navigation-active {
    background-color: rgb(0,0,0,.06);
}
.view-event-navigation > div {
    text-align: center;
    font-size: 15px !important;
    width: 50%;
    line-height: 30px;
    transition: 200ms;
    font-weight: 500;
}
.view-event-navigation > div:hover {
    background-color: rgb(0,0,0,.1);
    cursor: pointer;
}
</style>