<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" class="add-register-open-btn" prepend-icon="mdi-plus">Feiertag</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Feiertag erstellen">
                <v-card-text style="padding-bottom:20px">
                    Geben Sie alle Informationen für den Feiertag an um diesen zu erstellen.

                    <v-text-field v-model="holidayName" label="Name..." variant="outlined" class="add-register-textfield"></v-text-field>
                    <v-text-field v-model="holidayDate" label="Datum..." type="date" variant="outlined" class="add-register-textfield"></v-text-field>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red">{{ error_message }}</p>

                    <div class="add-register-action">
                        <div class="width-100"></div>
                        <v-btn @click="AddHoliday(isActive)">Erstellen</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddRegister-Component", 
    data() {
        return {
            holidayName: "",
            holidayDate: "",
            error_message: ""
        }
    },
    methods: {
        async AddHoliday(isActive) {
            if(await this.$validator.TextHasLegalLetters(this.holidayName) == false) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000056", "DE")
                return 
            }
            if(await this.$validator.IsValidDateInUSFormat(this.holidayDate) == false) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000057", "DE")
                return 
            }

            isActive.value = false
            this.error_message = "";
            
            this.$emit("add-holiday", {name: this.holidayName, date: this.holidayDate})
        }
    }
}
</script>

<style scoped>
@media(max-width: 800px) {
    .add-register-open-btn {
        min-width: 100% !important;
        margin: 5px !important;
    }
}
.add-register-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-register-open-btn {
    height:40px;
}
.add-register-textfield {
    margin-top:20px;
}
</style>