<template>
    <div class="sidebar">
        <img :src="this.$store.state.Config.Resources.Logo" :alt="this.$store.state.Config.Resources.Logo_Alt" class="sidebar-logo" />
    </div>
</template>

<script>
export default {
    name: "Sidebar-Authentication-Component"
}
</script>

<style scoped>
.sidebar{
    height: 100vh;
    width: 100px;
    margin: 0px;
    box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, .3);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.sidebar-logo {
    width: 37px;
    margin-left: calc((100px - 37px) / 2);
    margin-top: calc((100px - 50px) / 2);
}
@media(max-width: 680px) {
    .sidebar {
        height:50px;
        width: 100%;
        border-radius: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .sidebar-logo {
        margin-top:8px;
    }
}
</style>