<template>
    <v-app-bar elevation="1">
        <template v-slot:prepend>
            <v-app-bar-nav-icon @click="OpenNavigationBar"></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>Salonmanager</v-app-bar-title>

        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn icon="mdi-account" v-bind="props"></v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-card style="font-size: 16px !important;width:100px;padding:5px 0px 5px 10px;cursor: pointer;" variant="tonal" @click="SignOut">Abmelen</v-card>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: "Header-Component",
    data() {
        return {
            navigationOpen: true,
        }
    },
    mounted() {
        if(window.innerWidth < 1100) {
            this.navigationOpen = false;
            document.querySelector(".navigation").setAttribute("style", "min-width: 0px !important;width: 0px !important;height:calc(100vh - 60px) !important")
        }
    },
    methods: {
        SignOut() {
            this.$cookies.set(`${this.$store.state.Config.Cookies.Authentication}`, null)
            this.$router.push("/")
        },
        OpenNavigationBar() {
            if(!this.navigationOpen) {
                if(window.innerWidth > 1100) {
                    this.navigationOpen = true;
                    document.querySelector(".navigation").setAttribute("style", "min-width: 300px !important;width: 300px !important")
                }
                else {
                    document.querySelector(".navigation").setAttribute("style", "min-width: 300px !important;width: 300px !important;position:absolute;height:calc(100vh - 60px) !important")
                    this.navigationOpen = true;
                }
            } else {
                document.querySelector(".navigation").setAttribute("style", "min-width: 0px !important;width: 0px !important")
                this.navigationOpen = false;
            }
        }
    }
}
</script>