<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div style="position: relative">
                <div class="app-layout-header-controler" style="height:80px">
                    <div style="width:100%"></div>
                    <AddEmployee @create-employee="CreateEmployee" />
                </div>
                
                <v-table>
                    <thead>
                        <tr class="app-layout-table">
                            <th class="text-left">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="app-layout-table-item" v-for="employee in employees" :key="employee.id" @click="SelectItem(employee.Id)">
                            <td :class="{'table-item-disabled': employee.Status == '0'}"><v-icon style="padding-right:10px" icon="mdi-account"></v-icon> {{ employee.Firstname }} {{ employee.Lastname }} <div class="not-active">Nicht aktiv</div><div></div></td>
                        </tr>
                    </tbody>
                </v-table>

                <EditEmployee v-if="viewEmployee" :employee="selectedEmployee" @update-employees="UpdateEmplyoeePage" @close="CloseMenu" />
            </div>
        </div>
    </v-layout>
</template>

<script>
import CryptoJS from 'crypto-js';

import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

import EditEmployee from '../../../components/Applications/Salonmanager/EditEmployee.vue'
import AddEmployee from '../../../components/Applications/Salonmanager/AddEmployee.vue'

export default {
    name: "Salonmanager_Employees-View",
    components: { Header, Navigation, EditEmployee, AddEmployee },
    data() {
        return {
           viewEmployee: false,
           employees: [],
           selectedEmployee: {}
        }
    },
    async mounted() {
        await this.RequestAllEmployees()

        let employeeToView = this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.EmployeesToView}`)
       
        if(employeeToView !== null && employeeToView !== undefined && employeeToView !== "null") {
            this.selectedEmployee = employeeToView;
            this.viewEmployee = true;
        }
    },
    methods: {
        async CreateEmployee(employeeInfo) {
            const emplyoees = await this.SendPost("/salonmanager/create-employee", employeeInfo)
            this.FillEmployees(emplyoees)
        },
        CloseMenu() {
            this.viewEmployee = false;
            this.selectedEmployee = {}
            this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.EmployeesToView}`, null)
        },
        async SelectItem(id) {
            this.selectedEmployee = this.employees.find(e => e.Id == id); 
            
            if(this.selectedEmployee.Status == '0') {
                return;
            }

            if(this.selectedEmployee !== null && this.selectedEmployee !== undefined) {
                this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.EmployeesToView}`, JSON.stringify(this.selectedEmployee))
                this.viewEmployee = true;
            } 
        },
        async UpdateEmplyoeePage(employeesToLoad) {
            let employeesDecoded = JSON.parse(employeesToLoad)
            this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.EmployeesToView}`, null)
            await this.FillEmployees(employeesDecoded);
            await this.CloseMenu()
        },
        async RequestAllEmployees() {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const employees = this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.Employees}`)
            
                if(employees == null || employees == undefined || employees.length == 0) {
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Employees}`, null)
                }
                
                let result = await this.SendPost(this.$store.state.Config.Application.Salonmanager.PostURL+'get-employees', { hash: CryptoJS.SHA256(JSON.stringify(employees)).toString() })
               
                if(!Array.isArray(result)) {
                    this.FillEmployees(employees)
                } else {
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Employees}`, null)
                    this.$cookies.set(`${this.$store.state.Config.Cookies.Application.Salonmanager.Employees}`, JSON.stringify(result))
                    this.FillEmployees(result)
                } 
                this.$store.state.Config.Default.Pageloading = false
            } 
            catch(error) {
                console.log(error)
            }
        },
        FillEmployees(employees) {
            if(typeof employees === "string") {
                return;
            }
            
            this.employees = []
            this.employees = employees

            const employeeToView = JSON.parse(this.$cookies.get(`${this.$store.state.Config.Cookies.Application.Salonmanager.EmployeesToView}`))

            if(employeeToView == undefined || employeeToView == null) {
                return;
            } else {
                this.selectedEmployee = employeeToView
                this.viewEmployee = true;
            }
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    this.$emit("error", await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    }
}
</script>

<style scoped>
.table-item-disabled> div:last-child {
    width: 100%;
    height:100%;
    position: absolute;
    left:0%;
    top: 0%;
    background:rgba(255, 255, 255, 0.6);
}
.not-active {
    display: none;
}
.table-item-disabled > div:nth-child(2) {
    display: block !important;
    position: absolute;
    background-color: rgba(128, 128, 128, 0.192);
    padding: 3px 15px;
    z-index: 1;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    right: 0%;
    top: 0%;
    margin-top: 12.5px !important;
    margin-right: 12.5px;
    font-size: 13px !important;
}
.ministration-edit-btn-mobile {
    display: none !important;
}
.ministration-menu-btn-mobile {
    max-width:250px;
    min-width:250px;
    height:auto !important;
    left:calc(100% - 260px) !important;
}
.menu-list-btn > button {
    width: 100% !important;
    min-width: 100% !important;
    background:grey !important;
}
.ministration-notify-no-ministrations {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.app-layout-header-controler {
    margin-bottom: -5px;
    padding-right:15px;
    padding-left: 15px;
    display:flex;
    flex-direction: row;
    padding-top:20px;
    width: calc(100%px);
}
.ministration-btn {
    height:40px;
    margin-left:15px
}
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.ministration-select {
    width:260px;
    max-width: 260px;
    min-width: 260px
}
.app-layout-table {
    background: #1976D2;
    color:white;
    overflow: hidden;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
    position: relative;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
@media(max-width: 650px) {
    .table-holder {
        width: calc(100%);
        overflow:scroll !important
    }
    .table-holder-container {
        min-width: 650px;
        overflow:scroll !important;
    }
}
@media(max-width: 800px) {
    .app-layout-desktop-view {
        display: none !important;
    }
    .ministration-edit-btn-mobile{
        display: block !important;
    }
}
@media(max-width: 400px) {
    .ministration-select {
        width: 200px !important;
        min-width: 200px !important;
        max-width: 200px !important;
    }
}
</style>
