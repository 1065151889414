<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps">Bearbeiten</v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card title="Dienstleistung bearbeiten">
                <v-card-text style="padding-bottom:20px">
                    <v-text-field v-model="ministrationName" label="*Name..." variant="outlined" class="add-ministration-textfield"></v-text-field>
                    <v-text-field v-model="ministrationPrice" label="*Preis (00.00 EUR)..." type="number" variant="outlined"></v-text-field>
                    
                    <div class="flex-direction-row" style="width:100%">
                        <v-select label="Dauer" v-model="durationHours" :items="hours" variant="outlined"></v-select>
                        <div style="width:5px"></div>
                        <v-select :items="minutes" v-model="durationMinutes" variant="outlined"></v-select>
                    </div>

                    <p :class="{'EliasScharf_Hide': error_message == ''}" style="color:red">{{ error_message }}</p>

                    <div class="add-ministration-action">
                        <v-btn @click="DeleteMinistration(isActive)">Löschen</v-btn>
                        <div class="width-100"></div>
                        <v-btn @click="EditMinistration(isActive)">Speichern</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "AddMinistration-Component", 
    data() {
        return {
            ministrationName: "",
            ministrationPrice: "",
            ministrationDuration: "",
            
            durationHours: "",
            durationMinutes: "",
            
            hours: [],
            minutes: [],
            
            error_message: ""
        }
    },
    props: {
        ministration: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.ministrationName = this.ministration.Name;
        this.ministrationPrice = this.ministration.Price;

        this.FillTimeLists() 

        this.durationHours = this.ministration.Duration.split(':')[0]
        this.durationMinutes = this.ministration.Duration.split(':')[1]
    },
    methods: {
        FillTimeLists() {
            for(var i = 0; i < 60; i++) {
                if(i <= 9) {
                    this.minutes.push(`0${i}`)
                } else {
                    this.minutes.push(`${i}`)
                }
            }
            for(var x = 0; x < 101; x++) {
                if(x <= 9) {
                    this.hours.push(`0${x}`)
                } else {
                    this.hours.push(`${x}`)
                }
            }
        },
        async DeleteMinistration(isActive) {
            isActive.value = false
            this.$emit("delete-ministration", { ministrationId: this.ministration.Id })
        },
        async EditMinistration(isActive) {
            this.ministrationDuration = this.durationHours + ":" + this.durationMinutes
            
            if(!this.$validator.IsBetweenLength(this.ministrationName, 1, 255)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000010", "DE")
                return;
            }
            
            if(!this.$validator.IsValidPrice(this.ministrationPrice)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000013", "DE")
                return;
            }

            if(!this.$validator.IsValidTime(this.ministrationDuration)) {
                this.error_message = await this.$errorcodes.GetErrorMessage("000012", "DE")
                return;
            }

            isActive.value = false
            this.error_message = "";
            
            this.$emit("edit-ministration", { 
                ministrationName: this.ministrationName, 
                ministrationPrice: this.ministrationPrice,
                ministrationDuration: this.ministrationDuration,
                ministrationId: this.ministration.Id 
            })
        }
    }
}
</script>

<style scoped>
.add-ministration-action {
    display: flex;
    flex-direction: row;
    margin-top:10px;
}
.add-ministration-open-btn {
    height:40px;
    margin-left:15px
}
.add-ministration-textfield{
    margin-top: 20px;
}
</style>