<template>
    <div>
        <!-- ### Elias Scharf: Authentication Handler ### -->
    </div>
</template>

<script>
export default {
    name: "AuthenticationHandler-Component",
    data() {
        return {
            tokenRquested: false,
            firstRequest: true,
        }
    },
    mounted() {
        this.$store.state.Config.Default.Pageloading = true

        setTimeout(() => {
            this.TryAuthentication();
        }, 100)
    },
    methods: {
        async RefreshToken(route, token) {
            try {
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: null,
                });

                if (response.ok) {
                    const responseData = await response.json();

                    this.$store.state.Config.Authentication.Data.Token = responseData.Token
                    this.$store.state.Config.Authentication.Data.ExpiryDate = responseData.ExpiryDate
                    this.$store.state.Config.Authentication.Data.SecondsToExpire = responseData.SecondsToExpire
                    this.$store.state.Config.Authentication.Data.SecondsToExpire = responseData.SecondsToExpire
                    this.$store.state.Config.Authentication.Data.Admin = responseData.Admin

                    this.$cookies.set(`${this.$store.state.Config.Cookies.Authentication}`, btoa(JSON.stringify(responseData)))
                    this.tokenRquested = false;
                } else {
                    let errorCode = await response.text()
                    this.$store.state.Config.Application.General.Code = errorCode
                    this.tokenRquested = false;
                    this.$router.push(this.$store.state.Config.Authentication.VerifyFailedURL)
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        },
        async TryAuthentication() {
            if(this.tokenRquested) {
                setTimeout(() => {
                    this.TryAuthentication();
                }, 5000)
            }

            const authenticationData = this.$cookies.get(`${this.$store.state.Config.Cookies.Authentication}`)

            if(authenticationData !== null && authenticationData !== undefined && authenticationData !== 'null') {
                const decodedAuthenticationData = JSON.parse(atob(authenticationData));
                
                if(decodedAuthenticationData.Token == null || decodedAuthenticationData.ExpiryDate == null || decodedAuthenticationData.SecondsToExpire == null) {
                    this.$router.push(this.$store.state.Config.Authentication.VerifyFailedURL)
                } else {
                    this.$store.state.Config.Authentication.Data.Token = decodedAuthenticationData.Token
                    this.$store.state.Config.Authentication.Data.ExpiryDate = decodedAuthenticationData.ExpiryDate
                    this.$store.state.Config.Authentication.Data.SecondsToExpire = decodedAuthenticationData.SecondsToExpire
                    this.$store.state.Config.Authentication.Data.Admin = decodedAuthenticationData.Admin
                }

                if(this.$calculator.DateTimeIsBetweenBothTimes(this.$calculator.RemoveSecondsFromDateTime(decodedAuthenticationData.ExpiryDate, decodedAuthenticationData.SecondsToExpire), decodedAuthenticationData.ExpiryDate, this.$datetime.GetDateTime("XXXX-XX-XX HH:MM:SS"))) {
                    if(this.$calculator.DateTimeIsBetweenBothTimes(this.$calculator.RemoveSecondsFromDateTime(decodedAuthenticationData.ExpiryDate, 180), decodedAuthenticationData.ExpiryDate, this.$datetime.GetDateTime("XXXX-XX-XX HH:MM:SS"))) {
                        // Token is still valid, but can be requested again that user stays authenticated
                        this.tokenRquested = true;
                        this.RefreshToken('/authentication/verify-token', decodedAuthenticationData.Token);
                    } 

                    // Token is still active and does not need to be reset so it can be ignored
                }
                else {
                    this.$emit("error", "000073")

                    // Token is not valid anymore and user need to signin again
                    this.$router.push(this.$store.state.Config.Authentication.VerifyFailedURL)
                }
            } 
            else {
                this.$emit("error", "000073")
            }

            if(this.firstRequest) {
                this.firstRequest = false;
                this.$store.state.Config.Default.Pageloading = false
            }

            this.$emit("authentication-loaded")

            setTimeout(() => {
                this.TryAuthentication();
            }, 5000)
        },
    }
}
</script>