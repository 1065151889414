const dateTime = {
    GetTimeInfo: function() {
        var currentDate = new Date();
        var hours = currentDate.getHours();
        var minutes = currentDate.getMinutes();
        var seconds = currentDate.getSeconds();
        var milliseconds = currentDate.getMilliseconds();

        return { hours, minutes, seconds, milliseconds };
    },
    GetDateInfo: function() {
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        var day = ('0' + currentDate.getDate()).slice(-2);

        return { day, month, year};
    },
    GetEUDateFromUS(date) {
        return `${date.split('-')[2]}.${date.split('-')[1]}.${date.split('-')[0]}`
    },
    GetUSDateFromEU(date) {
        return `${date.split('.')[2]}-${date.split('.')[1]}-${date.split('.')[0]}`
    },
    GetTime: function (format) {
        var { hours, minutes, seconds, milliseconds } = this.GetTimeInfo();

        switch (format) {
            case 'HH:MM:SS.MS':
                return `${hours}:${minutes}:${seconds}.${milliseconds}`;
            case 'HH:MM:SS':
                return `${hours}:${minutes}:${seconds}`;
            case 'MM:SS.MS':
                return `${minutes}:${seconds}.${milliseconds}`;
            case 'HH:MM':
                return `${hours}:${minutes}`;
            case 'MM:SS':
                return `${minutes}:${seconds}`;
            case 'SS.MS':
                return `${seconds}.${milliseconds}`;
            default:
                return null;
        }
    },
    GetDate: function (format) {
        var { day, month, year } = this.GetDateInfo(); 

        switch (format) {
            case 'XXXX-XX-XX':
                return `${year}-${month}-${day}`;
            case 'XX.XX.XXXX':
                return `${day}.${month}.${year}`;
            default:
                return null;
        }
    },
    GetDateTime: function (format) {
        if(format == 'XXXX-XX-XX HH:MM:SS.MS') {
            return `${this.GetDate('XXXX-XX-XX')} ${this.GetTime('HH:MM')}`;
        }
        if(format == 'XX.XX.XXXX HH:MM:SS.MS') {
            return `${this.GetDate('XX.XX.XXXX')} ${this.GetTime('HH:MM:SS')}`;
        }
        if(format == 'XXXX-XX-XX HH:MM:SS') {
            return `${this.GetDate('XXXX-XX-XX')} ${this.GetTime('HH:MM:SS')}`;
        }
        if(format == 'XXXX-XX-XX HH:MM') {
            return `${this.GetDate('XXXX-XX-XX')} ${this.GetTime('HH:MM')}`;
        }
        if(format == 'XX.XX.XXXX HH:MM:SS') {
            return `${this.GetDate('XX.XX.XXXX')} ${this.GetTime('HH:MM:SS')}`;
        }
        if(format == 'XX.XX.XXXX HH:MM') {
            return `${this.GetDate('XX.XX.XXXX')} ${this.GetTime('HH:MM')}`;
        }
        
        return null;
    },
}

module.exports = dateTime;



