<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div>
                <div class="table-holder">
                    <v-table class="table-holder-container" style="min-height:440px;">
                        <thead>
                            <tr class="app-layout-table">
                                <th class="text-left">Name</th>
                                <th class="text-left">startzeit</th>
                                <th class="text-left">endzeit</th>
                                <th class="text-left" style="width:180px !important">arbeitstag</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="app-layout-table-item" v-for="openTime in openTimes" :key="openTime.id">
                                <td>{{ openTime.Name }}</td>
                                <td style="position: relative;"><v-text-field :disabled="!openTime.WorkDay" density="compcat" type="time" v-model="openTime.StartTime" style="position: absolute;top:10px" variant="outlined"></v-text-field></td>
                                <td style="position: relative;"><v-text-field :disabled="!openTime.WorkDay" density="compcat" type="time" v-model="openTime.EndTime" style="position: absolute;top:10px" variant="outlined"></v-text-field></td>
                                <td style="position: relative;"><v-switch color="primary" style="position: absolute;top:0%" v-model="openTime.WorkDay" @change="ChangeTimes(openTime.WorkDay, openTime)" label="Arbeitstag"></v-switch></td>
                            </tr>
                        </tbody>
                    </v-table>
                    <div class="flex-direction-row" style="padding-left: 20px;padding-right: 20px;">
                        <p style="width:100%;color:red">{{ error_message }} </p>
                        <div style="width:100%"></div>
                        <v-btn @click="UpdateOpenTimes">speichern</v-btn>
                    </div>
                </div>

            </div>
        </div>
    </v-layout>
</template>

<script>
//import CryptoJS from 'crypto-js';

import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

export default {
    name: "Salonmanager_Ministrations-View",
    components: { Header, Navigation, },
    data() {
        return {
            openTimes: [],
            isLoaded: false,
            error_message: "",
        }
    },
    async mounted() {
        this.RequestAllOpenTimes()
    },
    methods: {
        async RequestAllOpenTimes() {
            this.isLoaded = false;
            const openTimes = await this.SendPost("/salonmanager/request-opentimes")
            this.FillOpenTimes(openTimes)
        },
        async UpdateOpenTimes() {
            var doNotUpdate = false
            
            for (const openTime of this.openTimes) {
                if(!(openTime.StartTime == "00:00" && openTime.EndTime == "00:00")) {
                    const compareTimesStatus = await this.$validator.CompareTimes(`${openTime.StartTime.split(':')[0]}:${openTime.StartTime.split(':')[1]}`, `${openTime.EndTime.split(':')[0]}:${openTime.EndTime.split(':')[1]}`);
                    if(!compareTimesStatus) {
                        doNotUpdate = true;
                    }
                }
            }

            if(doNotUpdate) {
                this.error_message = "Beim bearbeiten der Öffnungszeiten ist etwas fehlgeschlagen. Bitte überprüfe deine Öffnungszeiten."
            } else {
                this.error_message = ""
                const openTimes = await this.SendPost("/salonmanager/update-opentimes", {openTimes: this.openTimes})
                this.FillOpenTimes(openTimes)
            }
        },
        SortWeekDaysByDayName(dayNames) {
            const dayOrder = {
                Montag: 1,
                Dienstag: 2,
                Mittwoch: 3,
                Donnerstag: 4,
                Freitag: 5,
                Samstag: 6,
                Sonntag: 7
            };

            return dayNames.sort((a, b) => dayOrder[a.Name] - dayOrder[b.Name]);
        },
        async FillOpenTimes(openTimes) {
            try {
                openTimes = this.SortWeekDaysByDayName(openTimes)
                this.openTimes = []

                if(openTimes !== null && openTimes !== undefined) {
                    openTimes.forEach(openTime => {
                        var isWorkingDay = true;
              
                        if(openTime.StartTime == "00:00:00" && openTime.EndTime == "00:00:00") {
                            isWorkingDay = false;
                        }

                        this.openTimes.push(
                            {
                                Id: openTime.Id, 
                                Name: openTime.Name,
                                StartTime: (openTime.StartTime.split(':')[0])+":"+openTime.StartTime.split(':')[1],
                                EndTime: (openTime.EndTime.split(':')[0])+":"+openTime.EndTime.split(':')[1],
                                WorkDay: isWorkingDay,
                            }
                        )
                    })
                }
                
                this.isLoaded = true;
            }
            catch (error) {
                console.log(error)
            }
        },
        async ChangeTimes(changedTo, openTime) {
            if(!changedTo) {
                const item = this.openTimes.find(e => e.Id == openTime.Id)
                item.StartTime = "00:00"
                item.EndTime = "00:00"
            }
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    this.$emit("error", await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    }
}
</script>

<style scoped>
.ministration-edit-btn-mobile {
    display: none !important;
}
.ministration-menu-btn-mobile {
    max-width:250px;
    min-width:250px;
    height:auto !important;
    left:calc(100% - 260px) !important;
}
.menu-list-btn > button {
    width: 100% !important;
    min-width: 100% !important;
    background:grey !important;
}
.ministration-notify-no-ministrations {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.app-layout-header-controler {
    margin-bottom: -5px;
    padding-right:15px;
    padding-left: 15px;
    display:flex;
    flex-direction: row;
    padding-top:20px;
    width: calc(100%px);
}
.ministration-btn {
    height:40px;
    margin-left:15px
}
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.ministration-select {
    width:260px;
    max-width: 260px;
    min-width: 260px
}
.app-layout-table {
    background: #1976D2;
    color:white;
    overflow: hidden;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
@media(max-width: 650px) {
    .table-holder {
        width: calc(100%);
        overflow:scroll !important
    }
    .table-holder-container {
        min-width: 650px;
        overflow:scroll !important;
    }
}
@media(max-width: 800px) {
    .app-layout-desktop-view {
        display: none !important;
    }
    .ministration-edit-btn-mobile{
        display: block !important;
    }
}
@media(max-width: 400px) {
    .ministration-select {
        width: 200px !important;
        min-width: 200px !important;
        max-width: 200px !important;
    }
}
</style>
