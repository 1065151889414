<template>
    <v-layout>
        <Header />

        <div class="app-layout">
            <Navigation />

            <div>
                <div class="app-layout-header-controler">
                    <div style="width:100%"></div>
                    <div class="app-layout-desktop-view flex-direction-row" style="min-height:60px">
                        <AddHoliday @add-holiday="CreateHoliday" />
                    </div>
                </div>
                
                <div class="table-holder">
                    <v-table class="table-holder-container">
                        <thead>
                            <tr class="app-layout-table">
                                <th class="text-left">Name</th>
                                <th class="text-left">date</th>
                                <th class="text-left" style="width:100px !important">Aktion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="app-layout-table-item" v-for="holiday in holidays" :key="holiday.id">
                                <td>{{ holiday.Name }}</td>
                                <td v-if="holiday.Date !== '' && holiday.Date !== null">{{ this.$datetime.GetEUDateFromUS(holiday.Date.split('T')[0]) }}</td>
                                <td v-else>-</td>
                                <td> <EditHoliday v-if="isLoaded" :holiday="holiday" @delete-holiday="DeleteHoliday" @update-holiday="UpdateHoliday" />  </td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
            </div>
        </div>
    </v-layout>
</template>

<script>
//import CryptoJS from 'crypto-js';

import Header from '../../../components/Default/Header.vue'
import Navigation from '../../../components/Applications/Salonmanager/Navigation.vue'

import AddHoliday from '../../../components/Applications/Salonmanager/AddHoliday.vue'
import EditHoliday from '../../../components/Applications/Salonmanager/EditHoliday.vue'

export default {
    name: "Salonmanager_Ministrations-View",
    components: { Header, Navigation, AddHoliday, EditHoliday },
    data() {
        return {
           holidays: [],
           isLoaded: false,
        }
    },
    async mounted() {
        this.RequestAllHolidays()
    },
    methods: {
        async RequestAllHolidays() {
            this.isLoaded = false;
            const holidays = await this.SendPost("/salonmanager/request-holidays")
            this.FillHolidays(holidays)
        },
        async DeleteHoliday(holidayInfo) {
            const holidays = await this.SendPost("/salonmanager/delete-holiday", holidayInfo)
            this.FillHolidays(holidays)
        },
        async UpdateHoliday(holidayInfo) {
            const holidays = await this.SendPost("/salonmanager/update-holiday", holidayInfo)
            this.FillHolidays(holidays)
        },
        async CreateHoliday(holidayInfo) {
            const holidays = await this.SendPost("/salonmanager/create-holiday", holidayInfo)
            this.FillHolidays(holidays)
        },
        FillHolidays(holidays) {
            this.holidays = []

            if(holidays !== null && holidays !== undefined) {
                this.holidays = holidays;
                
                this.holidays.forEach(holiday => {
                    const datetime = new Date(holiday.Date);
                    datetime.setHours(datetime.getHours() + 2);
                    holiday.Date = datetime.toISOString();
                })
            }
            
            this.isLoaded = true;
        },
        async SendPost(route, data) {
            try {
                this.$store.state.Config.Default.Pageloading = true
                const response = await fetch(`${this.$store.state.Config.Url.Server}${route}?token=${this.$store.state.Config.Authentication.Data.Token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify( data ),
                });

                if (response.ok) {
                    this.$store.state.Config.Default.Pageloading = false
                    return (await response.json())
                } else {
                    this.$store.state.Config.Default.Pageloading = false
                    this.$emit("error", await response.text())
                }
            } catch (error) {
                console.error('Error: ', error.message);
            }
        }
    }
}
</script>

<style scoped>
.ministration-edit-btn-mobile {
    display: none !important;
}
.ministration-menu-btn-mobile {
    max-width:250px;
    min-width:250px;
    height:auto !important;
    left:calc(100% - 260px) !important;
}
.menu-list-btn > button {
    width: 100% !important;
    min-width: 100% !important;
    background:grey !important;
}
.ministration-notify-no-ministrations {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
}
.app-layout-header-controler {
    margin-bottom: -5px;
    padding-right:15px;
    padding-left: 15px;
    display:flex;
    flex-direction: row;
    padding-top:20px;
    width: calc(100%px);
}
.ministration-btn {
    height:40px;
    margin-left:15px
}
.ministration-edit-btn {
    border-radius:10px;
    font-size: 14px !important;
    margin-left:10px;
    height:40px !important;
    width:40px !important;
}
.ministration-select {
    width:260px;
    max-width: 260px;
    min-width: 260px
}
.app-layout-table {
    background: #1976D2;
    color:white;
    overflow: hidden;
}
.app-layout-table > th {
    font-size: 14px;
    height: 50px !important;
    padding-top: 4px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.app-layout-table-item {
    font-size: 15px;
    transition: 50ms;
}
.app-layout-table-item:hover {
    background: rgba(211, 211, 211, 0.35);
    cursor: pointer;
}
@media(max-width: 650px) {
    .table-holder {
        width: calc(100%);
        overflow:scroll !important
    }
    .table-holder-container {
        min-width: 650px;
        overflow:scroll !important;
    }
}
@media(max-width: 800px) {
    .app-layout-desktop-view {
        display: none !important;
    }
    .ministration-edit-btn-mobile{
        display: block !important;
    }
}
@media(max-width: 400px) {
    .ministration-select {
        width: 200px !important;
        min-width: 200px !important;
        max-width: 200px !important;
    }
}
</style>
