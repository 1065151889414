const validator = {
    HasLength: function (text, lengthCount) {
        return text.length === lengthCount;
    },
    HasMinLength: function (text, minLengthCount) {
        return text.length >= minLengthCount;
    },
    HasMaxLength: function (text, maxLengthCount) {
        return text.length <= maxLengthCount;
    },
    IsBetweenLength: function (text, start, end) {
        if(this.HasMinLength(text, start) && this.HasMaxLength(text, end)) {
            return true;
        } else {
            return false;
        }
    },
    IsValidEmail: function (email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    },
    TextOnlyCharacters: function (text) {
        const characterRegex = /^[A-Za-z]+$/;
        return characterRegex.test(text);
    },
    TextOnlyCharctersAndSpace: function (text) {
        const characterRegex = /^[A-Za-z\s]+$/;
        return characterRegex.test(text);
    },
    TextOnlyNumbers: function(text) {
        const numberRegex = /^[0-9]+$/;
        return numberRegex.test(text);
    },
    TextOnlyNumbersAndDash: function(text) {
        const regex = /^[\d-]+$/;
        return regex.test(text);
    },
    TextOnlyNumbersAndPlus: function (text) {
        const regex = /^[0-9+]+$/;
        return regex.test(text)
    },
    TextOnlyCharactersAndNumersAndSpace: function(text) {
        const regex = /^[0-9a-zA-Z\s]+$/;
        return regex.test(text);
    },
    IsValidList: function (list) {
        if(list.length !== 0 && list !== undefined && list !== null) {
            return true;
        } else {
            return false;
        }
    },
    IsValidTime(time) {
        const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return timeRegex.test(time);
    },
    IsValidPrice(price) {
        const priceRegex = /^\d+(\.\d{1,2})?$/;
        return priceRegex.test(price);
    },
    IsTextEqualText(text1, text2) {
        return (text1 === text2)
    },
    IsValidDateInUSFormat(date) {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(date)
    },
    ValidRegisterName(registerName) {
        if(!validator.IsBetweenLength(registerName, 1, 255)) {
            return "000010"
        }

        if(!validator.TextOnlyCharctersAndSpace(registerName)) {
            return "000011"
        }

        return true;
    },
    MinistrationValuesAreValid(ministrationName, ministrationPrice, ministrationDuration) {
        if(!validator.IsBetweenLength(ministrationName, 1, 255)) {
            return "000010";
        }
        
        if(!validator.IsValidPrice(ministrationPrice)) {
            return "000013";
        }

        if(!validator.IsValidTime(ministrationDuration)) {
            return "000012";
        }

        return true;
    },
    HasValidRegisterName(registerName) {
        if(!this.TextOnlyCharctersAndSpace(registerName)) {
            return "000011"
        } 

        if(!this.IsBetweenLength(registerName, 1, 255)) {
            return "000010"
        } 

        return true;
    },
    CustomerInfoAreValid: async function (firstname, lastname, email, phone, birthday, city, street, houseNumber, postalCode) {
        if(
            this.TextOnlyCharacters(firstname) && this.IsBetweenLength(firstname, 1, 50) &&
            this.TextOnlyCharacters(lastname) && this.IsBetweenLength(lastname, 1, 50)
        ) {
            if(email !== undefined && email !== null && email.length !== 0) {
                if(!this.IsValidEmail(email) || !this.IsBetweenLength(email, 1, 50)) {
                    return "000021"
                }
            } else {
                email = null;
            }

            if(phone !== undefined && phone !== null && phone.length !== 0) {
                if(!this.IsBetweenLength(phone, 1, 50) || !this.TextOnlyNumbersAndPlus(phone)) {
                    return "000022"
                }
            } else {
                phone = null;
            }
        
            if(birthday !== undefined && birthday !== null && birthday.length !== 0) {
                birthday = String(birthday.split('T')[0].toString())
                if(!this.IsValidDateInUSFormat(birthday) || !this.TextOnlyNumbersAndDash(birthday) || !this.HasLength(birthday, 10)) {
                    return "000023"
                }
            } else {
                birthday = null;
            }

            var setAdress = false;

            if(city !== undefined && city !== null && city.length !== 0) {
                setAdress = true
                
                if(!this.TextOnlyCharctersAndSpace(city) || !this.IsBetweenLength(city, 1, 50)) {
                    return "000024"
                }
            } else {
                city = null;
            }

            if(street !== undefined && street !== null && street.length !== 0 && setAdress) {
                if(!this.TextOnlyCharctersAndSpace(street) || !this.IsBetweenLength(street, 1, 255)) {
                    return "000025"
                }
            } else {
                street = null;
            }

            if(houseNumber !== undefined && houseNumber !== null && houseNumber.length !== 0 && houseNumber && setAdress) {
                if(!this.TextOnlyNumbers(houseNumber) || !this.IsBetweenLength(houseNumber, 1, 6)) {
                    return "000026"
                }
            } else {
                houseNumber = null;
            }

            if(postalCode !== undefined && postalCode !== null && postalCode.length !== 0 && setAdress) {
                if(!this.TextOnlyNumbers(postalCode) || !this.IsBetweenLength(postalCode, 1, 6)) {
                    return "000027"
                }
            } else {
                postalCode = null;
            }

            if(postalCode == null || houseNumber == null || street == null || city == null) {
                city = null;
                postalCode = null;
                houseNumber = null;
                street = null;
            }
            return { firstname, lastname, email, phone, birthday, city, street, houseNumber, postalCode};
        } else {
            return "000028"
        }
    },
    EmployeeDataIsValid: async function (firstname, lastname, email, color) {
        if(!this.TextOnlyCharacters(firstname)) {
            return "000031"
        }
        if(!this.TextOnlyCharacters(lastname)) {
            return "000032"
        }
        if(!this.IsBetweenLength(firstname, 1, 255)) {
            return "000033"
        }
        if(!this.IsBetweenLength(lastname, 1, 255)) {
            return "000034"
        }
        if(!this.IsValidEmail(email)) {
            return "000021"
        }
        if(!this.HasMinLength(email, 1)) {
            return "000021"
        }
        if(!this.HasMinLength(email, 1)) {
            return "000021"
        }
        if(!this.HasLength(color, 7)) {
            return "000035"
        }
    },
    IsEventDataValid(members, famName, customerId, employeeId, type, firstname, lastname, email, phone, date, startTime, endTime, description) {
        let customerSelectecd = false;
        if(customerId !== undefined && customerId !== null && customerId.length !== 0) {
            if(!this.HasLength(customerId, 36)) {
                return "000048"
            } else {
                customerSelectecd = true;
            }
        } 
        
        if(type == "Einzelbesuch") {
            type = "0";
            famName = "";
            members = "";
        } else {
            type = "1"
            firstname = "";
            lastname = "";
        }
        
        if(type == "0" && !customerSelectecd && ((firstname == null || firstname == undefined || lastname == null || lastname == undefined))) {
            return "000069";
        }
        if(type == "1" && (famName == null || famName == undefined || members == null || members == undefined)) {
            return "000069";
        }
        
        try {
            if(type == "1" && (famName.length == 0 || members.length == 0)) {
                return "000069";
            }
        }
        catch (error) {
            console.info(error)
        }

        try {
            if(type == "0" && !customerSelectecd && (firstname.length == 0 || lastname.length == 0)) {
                return "000069";
            }
        }
        catch (error) {
            console.info(error)
        }

        if(firstname !== null && firstname !== undefined) {
            if(firstname.length !== 0 ) {
                if (!this.TextOnlyCharacters(firstname) || !this.IsBetweenLength(firstname, 1, 50) ||
                !this.TextOnlyCharacters(lastname) || !this.IsBetweenLength(lastname, 1, 50)) {
                    return "000070"
                }
            }
        }

        if(famName !== null && famName !== undefined ) {
            if(famName.length !== 0) {
                if((!this.TextOnlyCharctersAndSpace(famName)) || !this.IsBetweenLength(famName, 1, 255)) {
                    return "000070"
                }
            }
        }
        

       
            if(String(members).length !== 0) {
                if(!this.TextOnlyNumbers(members)) {
                    return "000067"
                }
                if(Number(members) >= 1000 || Number(members) <= 0) {
                    return "000067"
                }
            }

            if(email !== undefined && email !== null && email.length !== 0) {
                if(!this.IsValidEmail(email) || !this.IsBetweenLength(email, 1, 50)) {
                    return "000021"
                }
            } else {
                email = null;
            }

            if(phone !== undefined && phone !== null && phone.length !== 0) {
                if(!this.IsBetweenLength(phone, 1, 50) || !this.TextOnlyNumbersAndPlus(phone)) {
                    return "000022"
                }
            } else {
                phone = null;
            }
            
            if(employeeId !== undefined && employeeId !== null && employeeId.length !== 0) {
                if(!this.HasLength(employeeId, 36)) {
                    return "000048"
                }
            }
           
            if(!this.IsValidTime(startTime) || !this.IsValidTime(endTime)) {
                return "000012"
            }

            if(!this.CompareTimes(startTime, endTime)) {
                return "000051"
            } 
            
            if(date !== undefined && date !== null && date.length !== 0) {
                date = String(date.split('T')[0].toString())
                if(!this.IsValidDateInUSFormat(date) || !this.TextOnlyNumbersAndDash(date) || !this.HasLength(date, 10)) {
                    return "000023"
                }
            } else {
                return "000023"
            }

            if(!this.IsValidDescription(description)) {
                return "000050"
            }

        return true;
    },
    IsValidDescription(text) {
        const regex = /^[a-zA-Z0-9\s!,.\-_"/?%]*$/;
        return regex.test(text);
    },
    CompareTimes(time1, time2) {
        const [hours1, minutes1] = time1.split(':').map(Number);
        const [hours2, minutes2] = time2.split(':').map(Number);
    
        const totalMinutes1 = hours1 * 60 + minutes1;
        const totalMinutes2 = hours2 * 60 + minutes2;
    
        if (totalMinutes1 < totalMinutes2) {
            return true;
        }

        return false;
    },
    TextHasLegalLetters(text) {
        const regex = /^[a-zA-Z0-9,.\-_?%/!]+$/;
        return regex.test(text);
    },
    IsTimeBetweenOtherTimes(timeToCheck, startTime, endTime) {
        const [hoursToCheck, minutesToCheck] = timeToCheck.split(':').map(Number);
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);
    
        const totalMinutesToCheck = hoursToCheck * 60 + minutesToCheck;
        const totalMinutesStart = startHours * 60 + startMinutes;
        const totalMinutesEnd = endHours * 60 + endMinutes;
    
        return totalMinutesToCheck >= totalMinutesStart && totalMinutesToCheck <= totalMinutesEnd;
    }
}

module.exports = validator;