import { createRouter, createWebHistory } from 'vue-router';

import Authentication from '../views/Authentication/Authentication.vue';

// -- Applications --
import Salonmanager_Calendar from '../views/Applications/Salonmanager/Salonmanager_Calendar.vue';
import Salonmanager_Ministrations from '../views/Applications/Salonmanager/Salonmanager_Ministrations.vue';
import Salonmanager_Customers from '../views/Applications/Salonmanager/Salonmanager_Customers.vue';
import Salonmanager_Employees from '../views/Applications/Salonmanager/Salonmanager_Employees.vue';
import Salonmanager_VerifyAccount from '../views/Applications/Salonmanager/Salonmanager_VerifyAccount.vue';
import Salonmanager_Holidays from '../views/Applications/Salonmanager/Salonmanager_Holidays.vue';
import Salonmanager_Opentimes from '../views/Applications/Salonmanager/Salonmanager_Opentimes.vue';

const routes = [
  { path: '/', component: Authentication },

  // -- Applications --
  // Salonmanager
  { path: '/salonmanager/calendar', component: Salonmanager_Calendar },
  { path: '/salonmanager/ministrations', component: Salonmanager_Ministrations },
  { path: '/salonmanager/customers', component: Salonmanager_Customers },
  { path: '/salonmanager/employees', component: Salonmanager_Employees },
  { path: '/salonmanager/holidays', component: Salonmanager_Holidays },
  { path: '/salonmanager/opentimes', component: Salonmanager_Opentimes },
  { path: '/verify-account/:sessionId', component: Salonmanager_VerifyAccount },
  { path: '/verify-account/:sessionId/set-password', component: Salonmanager_VerifyAccount },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
