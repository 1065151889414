const ERRORS = [
    { code: '000000', messageDE: 'Fehler unbekannt.' },
    { code: '000001', messageDE: 'E-Mail und /-oder das Passwort sind falsch.' },
    { code: '000002', messageDE: 'Die gefundene Lizenz ist nicht mehr gültig.' },
    { code: '000003', messageDE: 'Es wurde kein gültiges Unternehmenskonto gefunden.' },
    { code: '000004', messageDE: 'Das einfügen des Authentifizierung-Schlüssel in die Datenbank ist fehlgeschlagen.' },
    { code: '000005', messageDE: 'Beim Abrufen des Authentifizierung-Schlüssel in der Datenbank ist etwas fehlgeschlagen.' },
    { code: '000006', messageDE: 'Der Authentifizierung-Schlüssel darf noch nicht angefragt werden (die Zeitdifferenz beträgt nicht zwischen 0 und 3 Minuten)' },
    { code: '000007', messageDE: 'Der Authentifizierung-Schlüssel ist entweder abgelaufen oder ungültig.' },
    { code: '000008', messageDE: 'Das erstellen eines neuen Registers (Salonmanager) ist fehlgeschlagen.' },
    { code: '000009', messageDE: 'Beim Abrufen der Register (Salonmanager) aus der Datenbank ist etwas fehlgeschlagen.' },
    { code: '000010', messageDE: 'Die Zeichenlänge des Registers ungültig.' },
    { code: '000011', messageDE: 'Ungültige Zeichen. Es dürfen nur Buchstaben und Leerzeichen verwendet werden.' },
    { code: '000012', messageDE: 'Ungültige Uhrzeit.' },
    { code: '000013', messageDE: 'Ungültiger Betrag.' },
    { code: '000014', messageDE: 'Beim Abrufen der Dienstleistungen (Salonmanager) aus der Datenbank ist etwas fehlgeschlagen.' },
    { code: '000015', messageDE: 'Das erstellen einr neuen Dienstleistung (Salonmanager) ist fehlgeschlagen.' },
    { code: '000016', messageDE: 'Beim bearbeiten der Dienstleistung (Salonmanager) ist fehlgeschlagen.' },
    { code: '000017', messageDE: 'Dienstleistungseintrag (Salonmanager) konnte nicht erfolgreich gelöscht werden.' },
    { code: '000018', messageDE: 'Das umbenennen des Registers (Salonmangaer) ist fehlgeschlagen.' },
    { code: '000019', messageDE: 'Beim Abrufen der Kundendaten (Salonmanager) aus der Datenbank ist etwas fehlgeschlagen.' },
    { code: '000020', messageDE: 'Beim erstellen eines neuen Kunden (Salonmanager) ist etwas fehlgeschlagen.' },
    { code: '000021', messageDE: 'Ungültige E-Mail.'},
    { code: '000022', messageDE: 'Ungültige Telefonnummer.'},
    { code: '000023', messageDE: 'Ungültiges Geburtsdatum.'},
    { code: '000024', messageDE: 'Ungültiger Stadtname.'},
    { code: '000025', messageDE: 'Ungültiger Straßenname.'},
    { code: '000026', messageDE: 'Ungültige Hausnummer.'},
    { code: '000027', messageDE: 'Ungültige Postleizahl.'},
    { code: '000028', messageDE: 'Ungültiger Vor/-Nachname.'},
    { code: '000029', messageDE: 'Kundenprofil konnte nicht gelöscht werden.'},
    { code: '000030', messageDE: 'Bearbeiten des Kundenprofils ist fehlgeschlagen.'},
    { code: '000031', messageDE: 'Ungültiger Vorname.'},
    { code: '000032', messageDE: 'Ungültiger Nachname.'},
    { code: '000033', messageDE: 'Vorname hat eine ungültiger Zeichenlänge.'},
    { code: '000034', messageDE: 'Nachname hat eine ungültiger Zeichenlänge.'},
    { code: '000035', messageDE: 'Ungültiger Farbencode.'},
    { code: '000036', messageDE: 'Mitarbeiterprofil konnte nicht erfolgreich erstellt werden.'},
    { code: '000037', messageDE: 'Beim Abrufen der Mitarbeiterprofile (Salonmanager) aus der Datenbank ist etwas fehlgeschlagen.' },
    { code: '000038', messageDE: 'Das erstellen des Mitarbeiterprofiles in die Datenbank (Salonmanager) ist fehlgeschlagen.' },
    { code: '000039', messageDE: 'Session-URL hat eine ungültiger Zeichenlänge.'},
    { code: '000040', messageDE: 'Session konnte nicht erfolgreich erstellt werden.'},
    { code: '000041', messageDE: 'E-Mail zur Aktivierung konnte nicht gesendet werden.'},
    { code: '000042', messageDE: 'Kontoaktivierungs-Sitzung ist nicht mehr gültig.'},
    { code: '000043', messageDE: 'Ungültiger Aktiverungscode.'},
    { code: '000044', messageDE: 'Ungültige Passwortlänge.'},
    { code: '000045', messageDE: 'Beim Versuch das Konto zu aktivieren ist etwas fehlgeschlagen.'},
    { code: '000046', messageDE: 'Beim Versuch die Kontoaktivierungs-Sitzung zu löschen ist etwas fehlgeschlagen.'},
    { code: '000047', messageDE: 'Es existiert bereits ein Mitarbeiterprofil mit dieser E-Mail-Adresse.'},
    { code: '000048', messageDE: 'Länge des Identifikators ist ungültig.'},
    { code: '000049', messageDE: 'Typ des Termines ist ungültig.'},
    { code: '000050', messageDE: 'Ungültige Beschreibung.'},
    { code: '000051', messageDE: 'Die Uhrzeit muss vor der anderen sein.'},
    { code: '000052', messageDE: 'Beim Abrufen aller Termine aus der Datenbank ist etwas fehlgeschalgen.'},
    { code: '000053', messageDE: 'Termin konnte nicht erstellt werden.'},
    { code: '000054', messageDE: 'Mitarbeiterprofil konnte nicht gelöscht werden.'},
    { code: '000055', messageDE: 'Mitarbeiterprofil konnte nicht deaktiviert werden.'},
    { code: '000056', messageDE: 'Ungültiger Feiertagsname.'},
    { code: '000057', messageDE: 'Ungültigers Datum.'},
    { code: '000058', messageDE: 'Beim Abrufen aller Feiertage aus der Datenbank ist etwas fehlgeschlagen.'},
    { code: '000059', messageDE: 'Beim erstellen des Feiertages ist etwas fehlgeschalgen.'},
    { code: '000060', messageDE: 'Beim löschen des Feiertages ist etwas fehlgeschalgen.'},
    { code: '000061', messageDE: 'Beim bearbeiten des Feiertages ist etwas fehlgeschalgen.'},
    { code: '000062', messageDE: 'Beim Abrufen aller Öffnungszeiten aus der Datenbank ist etwas fehlgeschlagen.'},
    { code: '000063', messageDE: 'Beim bearbeiten der Öffnungszeiten ist etwas fehlgeschlagen.'},
    { code: '000064', messageDE: 'Kein Kundenprofil wurde gefunden.'},
    { code: '000065', messageDE: 'Kein Mitarbeiterprofil wurde gefunden.'},
    { code: '000066', messageDE: 'Das einfügen der zugewiesenen Dienstleistungen in die Datenbank ist fehlgeschlagen.'},
    { code: '000067', messageDE: 'Ungültige Familienmitgliederanzahl.'},
    { code: '000068', messageDE: 'Ungültiger Familienname.'},
    { code: '000069', messageDE: 'Typ passt nicht mit Eingabe überein.'},
    { code: '000070', messageDE: 'Ungültiger Vor-/Nachname oder Familienname.'},
    { code: '000071', messageDE: 'Termin konnte nicht gelöscht werden.'},
    { code: '000072', messageDE: 'Zugehörige Dienstleistungen eines gelöschten Termines konnten nicht gelöscht werden.'},
    { code: '000073', messageDE: 'Ihr Konto konnte nicht authentifiziert werden.'},
]

const errorcodes = {
    GetErrorMessage: async function (code, language) {
        const element = ERRORS.find(element => element.code === code);
        if(language == 'DE') {
            return element.messageDE;
        } else if(language == 'EN') {
            return element.messageEN;
        } else {
            return null;
        }
    },
}

module.exports = errorcodes;